import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';

import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';


import { IEntity, IResult } from 'library/interface';

import useDeliveryService, { useBasicFilterDelivery } from './services/Delivery';

import useStoreService from './services/Store';

import {  IDelivery, IDeliveryArticle, IDeliveryArticleStore, defaultDelivery,defaultDeliveryArticle, defaultDeliveryArticleStore } from './models/Delivery';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import externalLibraryService from 'features/configuration/services/ExternalLibrary';
import {defaultExternalLibrary, defaultExternalLibraryClass, IExternalLibrary, IExternalLibraryClass} from 'features/configuration/models/ExternalLibrary';

import { useBasicFilterProduct } from 'features/setup/services/Product';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { isFalsy } from 'utility-types';
import { FormDialog } from 'components/ui/FormDialog';
import { Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import IEnumeration, {Enum_DELIVERY_STATUS, Enum_ARTICLE_OPTION, Enum_DELIVERY_STATUS_PURPOSE, IEnumerationItem} from 'features/configuration/models/Enumeration';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { DatePicker } from '@mui/x-date-pickers';
import EnhancedTable from 'components/ui/EnhancedTable';
import usePersonService, { useBasicFilterPerson, useBasicFilterPersonSearch } from 'features/production/services/Person';

import { IPerson, PersonType } from 'features/production/models/Person';
import { IProduct } from 'features/setup/models/Product';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import TextFieldRight from 'components/ui/TextFieldRight';
import { GrClose, GrSearch } from 'react-icons/gr';
import { debounce, sum } from 'lodash';
import { MdOutlineAdd } from 'react-icons/md';
import { IStore } from './models/Store';

export const DeliveryForm: FC<IDelivery> = (props: IDelivery = defaultDelivery) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {language: lg} = useRecoilValue(currentUserSessionAtom);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityPrintDrawer, checkEntitySaveAuthorization} = useEntityService();
  const {createDelivery, updateDelivery } = useDeliveryService();

  const {getStores } = useStoreService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions} = useEnumerationService();

  const { getPersonsSearchCount } = usePersonService();


  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterDelivery = useBasicFilterDelivery( 
    
    async (event: React.MouseEvent<unknown>, row: IDelivery) => {
      const {id, reference } = row;
      
      setIsSearchBoxShow(false);
      _setId(row.id);
    }
  );

  const refArticleIndex = useRef<number>(0);

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;
          
          // setParameterEnumeration(id, name);                                      
          // setOpenEnumerationFilter(false);
      }
  );

  const [openProductFilter, setOpenProductFilter] = useState(false);
  const basicFilterProduct = useBasicFilterProduct( 2,
      (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, name, type, description, filterOption} = row;

          if( type !== 'article') return;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);

          (refAppendDeliveryArticles.current??emptyFunc)({...defaultDeliveryArticle, id: 0, deliveryId: _id, articleId: id, 
                articleName: name , articleFilterOption: filterOption  ,option: '', 
                  });
                           
          setOpenProductFilter(false);
      }
  );

  const [openPersonFilter, setOpenPersonFilter] = useState(false);
  const basicFilterPerson = useBasicFilterPerson( 
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, type, firstName, lastName, birthDate, birthPlace, particularSign, corporationName, corporationType,
            portable1, portable2, email1, email2} = row;
        
        setValue('supplierId', id);

        setValue('supplierType', type);

        setValue('supplierFirstName', firstName);
        setValue('supplierLastName', lastName); 
        setValue('supplierCorporationName', corporationName);  

        setValue('supplierPortable1', portable1);   
        setValue('supplierPortable2', portable2);                    
        setValue('supplierEmail1', email1); 
        setValue('supplierEmail2', email2); 
                          
        setOpenPersonFilter(false);
      }
  );

  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          setParameterEntity(name ?? '', description?? '');                           
          setOpenEntityFilter(false);
      }
  );
  
  const setParameterEntity = (name: string, description: string) => {
   
    // const applicationQueryParameter = getValues().applicationQueryParameters.at(applicationQueryParameterIndex);
    // if(isFalsy(applicationQueryParameter)) return;

    // (refUpdateDeliveryArticle.current??emptyFunc)(applicationQueryParameterIndex,
    //   {...applicationQueryParameter, entityName: name, entityDescription: description });
  }
  
  const emptyFunc = (obj: any) => {}

  const methods = useForm<IDelivery>({defaultValues:defaultDelivery});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchSupplierType = watch('supplierType');
  const watchSupplierFirstName = watch('supplierFirstName');
  const watchSupplierLastName = watch('supplierLastName');
  const watchSupplierParticularSign = watch('supplierParticularSign');

  const watchSupplierCorporationName = watch('supplierCorporationName');

  const watchAccessories = watch('accessories');

  const [deliveryArticleIndex, setDeliveryArticleIndex] = useState<number>(-1);

  const { control: controlStoreQuantity, setValue: setValueStoreQuantity, 
    getValues: getValuesStoreQuantity, reset: resetStoreQuantity, watch: watchStoreQuantity } = useForm<IDeliveryArticleStore>({defaultValues:defaultDeliveryArticleStore});

  let { fields: storeQuantities, append: appendStoreQuantities, update: updateStoreQuantity ,remove: removeStoreQuantity,  } = useFieldArray({
    name: `deliveryArticles.${deliveryArticleIndex}.deliveryArticleStores`,
    control,            
  });

  type SearchPersonUsage = 'supplier';
  const [currentSearchPersonUsage, setCurrentSearchPersonUsage] = useState<SearchPersonUsage>('supplier');
  
  const [openPersonSearchFilter, setOpenPersonSearchFilter] = useState(false);
  const basicFilterPersonSearch = useBasicFilterPersonSearch( 
      () => {
        const { supplierType, supplierFirstName, supplierLastName, supplierParticularSign, supplierCorporationName } = getValues();
       
        return {firstName: supplierType === 'moral' ? supplierCorporationName : supplierFirstName, 
                lastName: supplierType === 'moral' ? supplierCorporationName : supplierLastName, particularSign: supplierParticularSign}; 

        //return refPersonSearch.current || {firstName: '', lastName: '', particularSign: ''};
      },      
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, type, firstName, lastName, birthDate, birthPlace, particularSign, corporationName,
            portable1, portable2, email1, email2} = row;
          
        setValue('supplierId', id);
        setValue('supplierType', type);
        setValue('supplierFirstName', firstName);
        setValue('supplierLastName', lastName);  
        setValue('supplierParticularSign', particularSign);  
        setValue('supplierCorporationName', corporationName);  
        setValue('supplierPortable1', portable1);   
        setValue('supplierPortable2', portable2);                    
        setValue('supplierEmail1', email1); 
        setValue('supplierEmail2', email2); 
                          
        setOpenPersonSearchFilter(false);
      }
  );

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IDelivery>,Error,IDelivery>(
      _id>0?updateDelivery:createDelivery, {   
        onSuccess: (data: IResult<IDelivery>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          //reset(data.data);
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Delivery')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Delivery',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    
    const {data: _data, refetch} = useQuery<IDelivery>(['Delivery', _id], () => retrieveEntity('Delivery',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Delivery'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_DELIVERY_STATUS, Enum_ARTICLE_OPTION, Enum_DELIVERY_STATUS_PURPOSE ] ));

    const {data: stores} = useQuery<IStore[]>( ['stores'], () => getStores( { name: '', description: '', location: ''} ));



      const handleClickOpenPerson = async (event: any) => {
        setOpenPersonFilter(true);
      }

      const handleClickRemovePerson = async (event: any) => {
        setValue('supplierId', 0);
        setValue('supplierFirstName', '');
        setValue('supplierLastName', '');
        setValue('supplierPortable1', '');
        setValue('supplierPortable2', '');
        setValue('supplierEmail1', '');
        setValue('supplierEmail2', '');
      }
  
      const handleSupplierType = ( event: React.MouseEvent<HTMLElement>, newSupplierType: PersonType ) => {
  
        if(newSupplierType === null) return;
        setValue('supplierType', newSupplierType);
  
      };

    const getInputAdornmentQueryParameter = (row: IDeliveryArticle, cellId: keyof IDeliveryArticle)  => ({
 
      toolTip: `${t('Details')} ...`,
      icon: MoreVertIcon,
      onClickIcon: (event: any, index: number, row: IDeliveryArticle ) => {                
        
      }  
   })

  const cellEditableArticle = (row: IDeliveryArticle, cellId: keyof IDeliveryArticle) => {
    return true;
  }

  const [openDeliveryArticle, setOpenDeliveryArticle] = useState<boolean>(false);

  
  const getInputAdornmentArticle = (row: IDeliveryArticle, cellId: keyof IDeliveryArticle)  => ({

    toolTip: `${t('Details')} ...`,
    icon: MoreVertIcon,
    //iconDisable: row.resourceId > 0,
    onClickIcon: (event: any, index: number, row: IDeliveryArticle ) => {
      //refArticleIndex.current = index;
      setDeliveryArticleIndex(index);
      setOpenDeliveryArticle(true);
    }  
  })

 const getArticleOptionList = (row: IDeliveryArticle, cellId: keyof IDeliveryArticle, 
    opts: {value: string, name: string}[]) => {        

    const {articleFilterOption} = row;
    
    const enumsFilter = (refEnumItems.current ?? []).filter(x => x.parentEnumerationItemCode === articleFilterOption);

    return getAsOptions(enumsFilter ,Enum_ARTICLE_OPTION);
}

const cellAllowedArticle = (row: IDeliveryArticle, val: number) => {
     
  debouncedArticle();
  return true;
}

const debouncedArticle = useRef(
  debounce( () => {
    const index = refArticleIndex.current;

    const deliveryArticle = getValues().deliveryArticles.at(index); 
    if(isFalsy(deliveryArticle)) return;
    
    const { unityNetAmount, unityTax, quantityDemand} = deliveryArticle!;    
    const amount = (unityNetAmount+unityTax)*quantityDemand;
    
    // (refUpdateDeliveryArticle.current??emptyFunc)(index, {...deliveryArticle!, 
    //     unityAmount: unityNetAmount+unityTax, amount: amount });

   // setValue(`deliveryArticles.${index}.unityAmount`, unityNetAmount, { shouldValidate: true });
    setValue(`deliveryArticles.${index}.unityAmount`, unityNetAmount+unityTax, { shouldValidate: true });
    setValue(`deliveryArticles.${index}.amount`, amount, { shouldValidate: true });

    setTotalAmount();
    
  }, 1000)
).current;

const setTotalAmount = () => {
  const {accessories, accessoriesTax} = getValues();
  const sumNetAmount = sum( getValues().deliveryArticles.map( ({unityNetAmount,quantityDemand }) => unityNetAmount*quantityDemand ) );
  const sumTaxAmount = sum( getValues().deliveryArticles.map( ({unityTax, quantityDemand}) => unityTax*quantityDemand ) );
  
  setValue('netAmount', sumNetAmount);
  setValue('tax', sumTaxAmount);
  setValue('amount', sumNetAmount + sumTaxAmount + accessories + accessoriesTax);
}

  const [headDeliveryArticleCells, setHeadDeliveryArticleCells]  = useState<HeadCell<IDeliveryArticle>[]>([]);
  useEffect(() => {
    setHeadDeliveryArticleCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'articleName', label : t('Name'),  display: true, type: 'string', width: 30, isEditable: cellEditableArticle,
        getInputAdornment: getInputAdornmentArticle},
      {id:'option', label : t('Option'),  display: true, type: 'string', width: 20, isEditable: cellEditableArticle,
        getOptions: getArticleOptionList },
      {id:'quantityDemand', label : t('Qty'),  display: true, type: 'numeric', width: 10, 
          isEditable: cellEditableArticle, isAllowed: cellAllowedArticle },

      {id:'unityNetAmount', label : `${t('Net')} U.`,  display: true, type: 'numeric', decimalScale: 2,   width: 15, 
          isEditable: cellEditableArticle, isAllowed: cellAllowedArticle },
      {id:'unityTax', label : `${t('Tax')} U.`,  display: true, type: 'numeric', decimalScale: 2,width: 10, 
        isEditable: cellEditableArticle, isAllowed: cellAllowedArticle},
      {id:'unityAmount', label : `${t('Amount')} U.`,  display: false, type: 'numeric', decimalScale: 2, width: 10, isEditable: cellEditableArticle, },
      {id:'amount', label : t('Amount'),  display: true, type: 'numeric', decimalScale: 2, width: 15 },
    ]  )
  }, [t,i18n])

  const refAppendDeliveryArticles = useRef<(value: Partial<FieldArray<IDelivery>> | Partial<FieldArray<IDelivery>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateDeliveryArticle = useRef<(index: number,value: Partial<FieldArray<IDelivery>> ) => void>(null);
  const refRemoveDeliveryArticle = useRef<(index: number ) => void>(null);
  
  const handleDeliveryArticleSelected = (event: React.MouseEvent<unknown>,index: number,row: IDeliveryArticle) => {
    setDeliveryArticleIndex(index);
  }

  const handleAddDeliveryArticle = (event: any) => {
    setOpenProductFilter(true);      
  }

  const deliveryArticleRowActionIcon = ( applicationQueryParameter: IDeliveryArticle) : ActionIconTableRow<IDelivery,IDeliveryArticle> => {
  
    const res: ActionIconTableRow<IDelivery,IDeliveryArticle> = {
      toolTip: 'remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IDeliveryArticle) => {
        
        (refRemoveDeliveryArticle.current??emptyFunc)(index);            
      }
    }
    return res;
}

  const [storeQuantityIndex, setStoreQuantityIndex] = useState<number>(-1);
  const [openStoreQuantityDialog, setOpenStoreQuantityDialog] = useState<boolean>(false);
  const handleClickAddStoreQuantity = (event: any) => {
    resetStoreQuantity({...defaultDeliveryArticleStore});
    setStoreQuantityIndex(-1);
    setOpenStoreQuantityDialog(true);    
  }

  const handleOkStoreQuantity = (event: any) => {

    var store = (stores|| []).find(s => s.id === getValuesStoreQuantity().storeId);
    if(isFalsy(store)) return;

    var deliveryArticle = getValues().deliveryArticles.at(deliveryArticleIndex);
    if(isFalsy(deliveryArticle)) return;   

    // in case of add or edit, we can do this sum...
    var tmpSum = sum( storeQuantities.filter(x => x.storeId !== getValuesStoreQuantity().storeId ).map(x => x.quantityDemand));
    if(tmpSum + getValuesStoreQuantity().quantityDemand > deliveryArticle.quantityDemand) {
      enqueueSnackbar(` ${t('Invalid quantity')} - ${tmpSum}+${getValuesStoreQuantity().quantityDemand} ::: ${deliveryArticle.quantityDemand}` , 
          { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      return;
    }

    if(storeQuantityIndex<0) {
      if(getValues().deliveryArticles[deliveryArticleIndex].deliveryArticleStores.some(x => x.storeId === getValuesStoreQuantity().storeId)) {
        enqueueSnackbar( t('Invalid data !!!'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        return;
      } 
      appendStoreQuantities({...getValuesStoreQuantity(), storeName: store!.name});    
    }      
    else {
      var otherIndex = getValues().deliveryArticles[deliveryArticleIndex].deliveryArticleStores.findIndex(x => 
                x.storeId === getValuesStoreQuantity().storeId);
      if(otherIndex >= 0 && otherIndex != storeQuantityIndex) {
        enqueueSnackbar( t('Invalid data !!!'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        return;
      }

      updateStoreQuantity(storeQuantityIndex, {...getValuesStoreQuantity()});
    }      

    setOpenStoreQuantityDialog(false);    
  }
  
  const handleClickEditStoreQuantity = (event: any, index: number) => {
    const deliveryArticle = getValues().deliveryArticles.at(deliveryArticleIndex);
    if(isFalsy(deliveryArticle)) return;

    const storeQuantity = deliveryArticle!.deliveryArticleStores.at(index);
    if(isFalsy(storeQuantity)) return;

    setStoreQuantityIndex(index);
    resetStoreQuantity({...storeQuantity! });
    setOpenStoreQuantityDialog(true);
  }

const debouncedSupplierChange = useRef(
  debounce( async () => {      
    const { supplierType, supplierId, supplierFirstName, supplierLastName, supplierParticularSign, supplierCorporationName } = getValues();

    if(supplierId>0) return;

    if( supplierType === 'physical' &&  
        (isFalsy(supplierFirstName) || supplierFirstName === '') && (isFalsy(supplierLastName) || supplierLastName === '' )) return;

    if( supplierType === 'moral' &&  (isFalsy(supplierCorporationName) || supplierCorporationName === '') ) return;

    const fn = (supplierType === 'moral') ? supplierCorporationName : supplierFirstName;
    const ln = (supplierType === 'moral') ? supplierCorporationName : supplierLastName;

    const count = await getPersonsSearchCount(fn, ln, supplierParticularSign);

    if(!isFalsy(refSupplierSnackbarId.current)) closeSnackbar(refSupplierSnackbarId.current!);
    
    if(count > 0) 
      refSupplierSnackbarId.current = enqueueSnackbar( `${t('Identity')} --> ${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
            anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
            action: xActionPersonSearch('supplier') } );             

  }, 1500)
).current;

  const refSupplierSnackbarId = useRef<SnackbarKey>();

  const xActionPersonSearch = (searchPersonUsage: SearchPersonUsage): SnackbarAction => (snackbarId: SnackbarKey) => (
    <>        
        <Button onClick={() => { 
                // setUrlPdf(`${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`); 
                setCurrentSearchPersonUsage(searchPersonUsage);                  
                setOpenPersonSearchFilter(true);
                closeSnackbar(snackbarId); }}>   
            <GrSearch size={24} />
        </Button>
        <Button onClick={() => { closeSnackbar(snackbarId) }}>
            <GrClose size={24} />
        </Button>
    </>
  )

useEffect( () => {     
  setCurrentSearchPersonUsage('supplier');         
  debouncedSupplierChange();      
}, [watchSupplierFirstName, watchSupplierLastName, watchSupplierParticularSign, watchSupplierCorporationName ,debouncedSupplierChange]);

useEffect( () => {     
      
  debouncedArticle();      
}, [watchAccessories ,debouncedArticle]);


const refEnumItems = useRef<IEnumerationItem[]>();    
useEffect( () => {   
    refEnumItems.current = enumItems;
  
}, [enumItems]);


useEffect( () => {              
  setCurrentFormNameAtom(t('Delivery'));  
  setCurrentBasicTextFilterProps(basicFilterDelivery);
}, []);

  

  /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
and the new useEffect will take place ********************/
useEffect( () => {
    // setCurrentFormName(t('Billing'));        
    setCurrentFormNameAtom(_id>0?`${t('Delivery')} - # ${_id} # -`: t('Delivery') );
    if(_id > 0)
      retrieveData('Delivery',_id, refetch);  
  }, [_id] );

  useEffect( () => {   
    refArticleIndex.current = deliveryArticleIndex;
  
  }, [deliveryArticleIndex]);


useEffect( () => {
   
    if(_data && _data.id > 0) {
    reset(_data);
}
}, [_data]);

const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
  _setId(0);           
  reset(defaultDelivery);    
}

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {   
  if(!checkEntitySaveAuthorization('Delivery', _id)) {
    setIsSaveLoading(false);
       return;
  }

  const data = getValues(); 

  const {reference, description, netAmount, tax, accessories, accessoriesTax, amount} = data;

  if(netAmount + tax + accessories + accessoriesTax !== amount) {
    enqueueSnackbar( t('Invalid amount'), { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
    setIsSaveLoading(false);
    return;
  }

  if( data.deliveryArticles.some(a => a.unityNetAmount+a.unityTax !== a.unityAmount) ) {
    enqueueSnackbar( `${t('Invalid amount')} - ${t('Details')}`, { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
    setIsSaveLoading(false);
    return;
  }

  if(reference.trim() === '' || description.trim() === '') {
      enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
    }

  if( isFalsy(data.supplierFirstName) && isFalsy(data.supplierLastName) && isFalsy(data.supplierCorporationName) ) {
    enqueueSnackbar( t("The supplier's name is required"), { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
    setIsSaveLoading(false);
    return;
  }

  if(data.deliveryArticles.some( x => x.option.trim() === '' || x.quantityDemand === 0 )) {
    enqueueSnackbar( t('All articles must have option and quantity defined'), { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
    setIsSaveLoading(false);
    return;
  }

  mutate(data);
}

const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
  openEntityActionDrawer('Delivery', _id);
}

const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
  openEntityPrintDrawer('Delivery', _id);
}

const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
  queryClient.invalidateQueries(['Delivery',_id]);        
  await retrieveData('Delivery',_id, refetch);        
  reset(_data);             
}

  return (
        <FormProvider {...methods} >
          <Box sx={{ mx: 0.1 }}>
            <Grid container rowSpacing={3} columnSpacing={1}>
              <Grid item xs={12} md={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                <Stack flexDirection='column'  >
                    <Box sx={{ mt: 1, width: '100%' }} >
                        <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                        <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                        <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                        <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                        <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />

                        
                        <TextField sx={{width:'calc(70% - 8px)'}} id="reference" label={t('Reference')} 
                            inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus {...register('reference')} />
                        <Controller control={control}
                          name='deliveryDate' 
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <DatePicker label={t('Date')} 
                              onChange={onChange}                      
                              value={value}
                              renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                            /> )}
                        />
                    </Box>
                    <Box sx={{ mt: 1, width: '100%' }} >
                      <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                    </Box>
                    <Box sx={{ mt: 0.25, width: '100%' }} > 
                      <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                            sx={{...typographyGroupBoxStyling}}>
                        {`${t(('Identity of supplier'))} `}
                      </Typography>                                                       
                    </Box>
                    <Box sx={{ mt: 1, width: '100%' }} >
                      <ToggleButtonGroup value={watchSupplierType} exclusive key={watchSupplierType} size="small" onChange={handleSupplierType} aria-label="text alignment" fullWidth >
                        <ToggleButton value="moral" aria-label="centered">
                          {t('Moral person')}
                        </ToggleButton>
                        <ToggleButton value="physical" aria-label="centered">
                          {t('Physical person')}
                        </ToggleButton>    
                      </ToggleButtonGroup>                                                             
                    </Box>
                    <Box sx={{ mt: 1, width: '100%' }} key={` person ${getValues().supplierId}`}>                              
                      <TextField sx={{width:'calc(20% - 8px)'}} id="personId" label={t('Supplier Id')} 
                        {...register('supplierId')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">                                            
                              { (isFalsy(getValues().supplierId) ||  getValues().supplierId<=0) ?
                                    <IconButton color="primary" onClick={handleClickOpenPerson}>
                                      <ArrowDropDownCircleIcon />
                                    </IconButton> : 
                                    <IconButton color="primary" onClick={handleClickRemovePerson}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  }                                                                                              
                          </InputAdornment>
                        ) 
                      }} />
                      {watchSupplierType === 'physical' && <TextField sx={{width:'calc(30% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                        {...register(lg.startsWith('fr')?'supplierLastName':'supplierFirstName')} inputProps={ {readOnly: (getValues().supplierId>0) }}  /> }   
                      {watchSupplierType === 'physical' && <TextField sx={{width:'calc(30% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                        {...register(lg.startsWith('fr')?'supplierFirstName':'supplierLastName')} inputProps={ {readOnly: (getValues().supplierId>0) }}  /> } 
                      {watchSupplierType === 'physical' && <TextField sx={{width:'calc(20% - 8px)'}} id="ln" label={t('Particular sign')} 
                        {...register('supplierParticularSign')} inputProps={ {readOnly: (getValues().supplierId>0) }} /> }
                      {watchSupplierType === 'moral' && <TextField sx={{width:'calc(80% - 8px)'}} id="cn" label={t('Name')} 
                        {...register('supplierCorporationName')} inputProps={ {readOnly: (getValues().supplierId>0) }} /> }                              
                      { openPersonFilter && <FormDialog open={openPersonFilter} maxWidth='md'
                          okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                          onClose={()=> {setOpenPersonFilter(false);}} onOk={()=> {setOpenPersonFilter(false);}}  >
                              <BasicTextFilterForm<IPerson> {...basicFilterPerson } />
                      </FormDialog> }
                    </Box>   
                    <Box sx={{ mt: 1, width: '100%' }} > 
                      <TextField sx={{width:'calc(25% - 8px)'}} id="supplierPortable1" label={`${t('Portable')} 1`} 
                        {...register('supplierPortable1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: (getValues().supplierId>0) } }  />
                      <TextField sx={{width:'calc(75% - 8px)'}} id="supplierEmail1" label={`${t('Email')} 1`} 
                        {...register('supplierEmail1')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: (getValues().supplierId>0) } }/>  
                      
                      { openPersonSearchFilter && <FormDialog open={openPersonSearchFilter} maxWidth='md'
                          okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                          onClose={()=> {setOpenPersonSearchFilter(false);}} onOk={()=> {setOpenPersonSearchFilter(false);}}  >
                              <BasicTextFilterForm<IPerson> {...basicFilterPersonSearch } />
                      </FormDialog> } 
                    </Box> 
                    <Box sx={{ mt: 1, width: '100%' }} > 
                      <TextField sx={{width:'calc(25% - 8px)'}} id="supplierPortable2" label={`${t('Portable')} 2`} 
                        {...register('supplierPortable2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: (getValues().supplierId>0) } }/>   
                      <TextField sx={{width:'calc(75% - 8px)'}} id="supplierEmail2" label={`${t('Email')} 2`} 
                        {...register('supplierEmail2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: (getValues().supplierId>0) } }/>                                                    

                      
                    </Box>
                    <Box sx={{ mt: 0.25, width: '100%' }} > 
                      <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                            sx={{...typographyGroupBoxStyling}}>
                        {`${t(('Amounts related to delivery'))} `}
                      </Typography>                                                       
                    </Box>
                    <Box sx={{ mt: 1, width: '100%' }} >
                      <Controller                                
                        render={({ field: {onChange, onBlur, name, value, ref} }) => {
                          return (
                            <NumberFormat      
                              decimalScale={3}
                              label={`${t('Net amount')}`} sx={{width:'calc(50% - 8px)'}}
                              allowEmptyFormatting={false}
                              control={control}             
                                            
                              onValueChange={ (v) => onChange(v.floatValue) }              
                              //fixedDecimalScale={true} 
                              thousandSeparator={true}
                              disabled
                              //{...field}
                              customInput={TextFieldRight}
                              defaultValue={value}
                              value={value}
                              //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                            />
                          );
                        }}
                        name={`netAmount`}
                        //name={`${fieldsName}.${idx}.${cell.id}`}
                        control={control}
                      />
                      
                      <Controller                                
                        render={({ field: {onChange, onBlur, name, value, ref} }) => {
                          return (
                            <NumberFormat      
                              decimalScale={3}
                              label={`${t('Accessories')}`} sx={{width:'calc(50% - 8px)'}}
                              allowEmptyFormatting={false}
                              control={control}             
                                            
                              onValueChange={ (v) => onChange(v.floatValue) }              
                              //fixedDecimalScale={true} 
                              thousandSeparator={true}
                              //{...field}
                              customInput={TextFieldRight}
                              defaultValue={value}
                              value={value}
                              //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                            />
                          );
                        }}
                        name={`accessories`}
                        //name={`${fieldsName}.${idx}.${cell.id}`}
                        control={control}
                      />
                      
                      
                    </Box>
                    <Box sx={{ mt: 1, width: '100%' }} >
                      <Controller                                
                          render={({ field: {onChange, onBlur, name, value, ref} }) => {
                            return (
                              <NumberFormat      
                                decimalScale={3}
                                label={`${t('Tax')}`} sx={{width:'calc(25% - 8px)'}}
                                allowEmptyFormatting={false}
                                control={control}             
                                onValueChange={ (v) => onChange(v.floatValue) }              
                                //fixedDecimalScale={true} 
                                thousandSeparator={true}
                                disabled
                                //{...field}
                                customInput={TextFieldRight}
                                defaultValue={value}
                                value={value}
                                //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                              />
                            );
                          }}
                          name={`tax`}
                          //name={`${fieldsName}.${idx}.${cell.id}`}
                          control={control}
                        />
                      <Controller                                
                        render={({ field: {onChange, onBlur, name, value, ref} }) => {
                          return (
                            <NumberFormat      
                              decimalScale={3}
                              label={`${t('Accessories')} - ${t('Tax')}`} sx={{width:'calc(25% - 8px)'}}
                              allowEmptyFormatting={false}
                              control={control}             
                                            
                              onValueChange={ (v) => onChange(v.floatValue) }              
                              //fixedDecimalScale={true} 
                              thousandSeparator={true}
                              disabled
                              //{...field}
                              customInput={TextFieldRight}
                              defaultValue={value}
                              value={value}
                              //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                            />
                          );
                        }}
                        name={`accessoriesTax`}
                        //name={`${fieldsName}.${idx}.${cell.id}`}
                        control={control}
                      />
                      <Controller                                
                        render={({ field: {onChange, onBlur, name, value, ref} }) => {
                          return (
                            <NumberFormat      
                              decimalScale={3}
                              label={`${t('Amount')}`} sx={{width:'calc(50% - 8px)'}}
                              allowEmptyFormatting={false}
                              control={control}             
                                            
                              onValueChange={ (v) => onChange(v.floatValue) }              
                              //fixedDecimalScale={true} 
                              thousandSeparator={true}
                              disabled
                              //{...field}
                              customInput={TextFieldRight}
                              defaultValue={value}
                              value={value}
                              //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                            />
                          );
                        }}
                        name={`amount`}
                        //name={`${fieldsName}.${idx}.${cell.id}`}
                        control={control}
                      />
                    </Box>
                    <Box sx={{ mt: 0.25, width: '100%' }} > 
                      <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                            sx={{...typographyGroupBoxStyling}}>
                        {`${t(('Status of delivery'))} `}
                      </Typography>                                                       
                    </Box>
                    <Box sx={{ mt: 1, width: '100%' }} >
                      <Controller control={control}
                          name='issueDate' 
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <DatePicker label={t('Issue date')} 
                              onChange={onChange} readOnly disableOpenPicker                     
                              value={value}
                              renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                            /> )}
                      />
                      <Controller 
                          name={`status`}
                          control={control}
                          render={ ({field: {onChange, value}}) => (
                            <TextField select onChange={onChange} value={value} sx={{width:'calc(70% - 8px)'}} id="status"
                                label={t('Status')} inputProps={ {readOnly: true }} >
                                {enumItems && enumItems.filter( e => 
                                      e.enumerationCode === Enum_DELIVERY_STATUS ).map( 
                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                }
                              </TextField>
                          )}
                      />
                      
                    </Box>
                    <Box sx={{ mt: 1, width: '100%' }} >
                      <Controller control={control}
                            name='statusDate' 
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DatePicker label={t('Status date')} 
                                onChange={onChange} readOnly disableOpenPicker                     
                                value={value}
                                renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                              /> )}
                        />
                        <Controller 
                            name='statusPurpose' control={control}                                     
                            render={ ({field: {onChange, value}}) => (
                              <TextField select onChange={onChange} value={value} sx={{width:'calc(70% - 8px)'}} id="status"
                                label={t('Status purpose')} inputProps={ {readOnly: true}} >
                                {enumItems && enumItems.filter( e => 
                                      e.enumerationCode === Enum_DELIVERY_STATUS_PURPOSE ).map( 
                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                }
                              </TextField>
                            )}
                        />
                    </Box>
                </Stack>                        
              </Grid>                   
              <Grid item xs={12}  md={8} component={Paper} >
                  <Stack flexDirection='column'> 
                    <Box key={`${'watchFileName'} - key`} sx={{ mt: 1, width: '100%' }} >
                      <Controller 
                        name='defaultStoreId' control={control}                                     
                        render={ ({field: {onChange, value}}) => (
                          <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="status"
                            label={t('Default store or showroom for delivery')} inputProps={ {readOnly: false}} >
                            {stores && stores.map( 
                              (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                            }
                          </TextField>
                        )}
                      />
                    </Box>
                    <Box key={`${'watchFileName'} - key ${getValues().deliveryArticles.map(x => x.amount)}`} sx={{ mt: 1, width: '100%' }} >
                      <ArrayFieldTableEx<IDelivery,IDeliveryArticle,'id'> 
                        mainObject={getValues()} fieldKey='id' 
                        headCells={headDeliveryArticleCells} rowsPathName={`deliveryArticles` }
                        title={t('_Articles')} rowActionIcon={deliveryArticleRowActionIcon}  
                        onRowSelected={handleDeliveryArticleSelected}
                                            
                        refAppend={refAppendDeliveryArticles as MutableRefObject<(value: Partial<FieldArray<IDelivery>> | Partial<FieldArray<IDelivery>>[], options?: FieldArrayMethodProps) => void>}
                        refUpdate={refUpdateDeliveryArticle as MutableRefObject<(index: number,value: Partial<FieldArray<IDelivery>>) => void>}
                        refRemove={refRemoveDeliveryArticle as MutableRefObject<(index: number) => void>}

                        //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                        //displayMore={undefined}
                        toolbarActions={[
                        { toolTip: `${t('Add')}...`, onClickIcon: handleAddDeliveryArticle ,icon: AddCircleIcon,  },
                        
                        ]}
                      />  
                      { openProductFilter && <FormDialog open={openProductFilter} maxWidth='md'
                        okText={t('OK')} cancelText={t('Cancel')} title={t('Product filter')} onCancel={()=> {setOpenProductFilter(false);}} 
                        onClose={()=> {setOpenProductFilter(false);}} onOk={()=> {setOpenProductFilter(false);}}  >
                            <BasicTextFilterForm<IProduct> {...basicFilterProduct } />
                        </FormDialog> }
                        {deliveryArticleIndex >= 0 && openDeliveryArticle && <FormDialog open={openDeliveryArticle} maxWidth='sm' height='65vh'
                              okText='' cancelText='' 
                              title={`${getValues().deliveryArticles[deliveryArticleIndex].articleName} - ==> ${t('Store')} / ${t('Showroom')} `} 
                              onCancel={()=> {}} 
                              onClose={()=> {setOpenDeliveryArticle(false);}} onOk={()=> {setOpenDeliveryArticle(false);}}  >
                            <Stack flexDirection='column'>                          
                          
                              <Box sx={{ mt: 0.25, width: '100%' }} > 
                                <Typography variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                  {`... ${t('Amounts related to expense')}  ...`}
                                </Typography>                                                       
                              </Box>
                              <Box sx={{ mt: 1, width: '100%' }} >
                                <Controller                                
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat      
                                        decimalScale={3}
                                        label={`${t('Net amount')} - ${t('Unity')}`} sx={{width:'calc(33% - 8px)'}}
                                        allowEmptyFormatting={false}
                                        control={control}             
                                        disabled              
                                        onValueChange={ (v) => onChange(v.floatValue) }              
                                        //fixedDecimalScale={true} 
                                        thousandSeparator={true}
                                        //{...field}
                                        customInput={TextFieldRight}
                                        defaultValue={value}
                                        value={value}
                                        //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                      />
                                    );
                                  }}
                                  name={`deliveryArticles.${deliveryArticleIndex}.unityNetAmount`}
                                  //name={`${fieldsName}.${idx}.${cell.id}`}
                                  control={control}
                                />
                                <Controller                                
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat      
                                        decimalScale={3}
                                        label={`${t('Tax')} - ${t('Unity')}`} sx={{width:'calc(33% - 8px)'}}
                                        allowEmptyFormatting={false}
                                        control={control}       
                                        disabled      
                                        onValueChange={ (v) => onChange(v.floatValue) }              
                                        //fixedDecimalScale={true} 
                                        thousandSeparator={true}
                                        //{...field}
                                        customInput={TextFieldRight}
                                        defaultValue={value}
                                        value={value}
                                        //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                      />
                                    );
                                  }}
                                  name={`deliveryArticles.${deliveryArticleIndex}.unityTax`}
                                  //name={`${fieldsName}.${idx}.${cell.id}`}
                                  control={control}
                                />                                
                                <Controller                                
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat      
                                        decimalScale={3}
                                        label={`${t('Amount')}  - ${t('Unity')}`} sx={{width:'calc(34% - 8px)'}}
                                        allowEmptyFormatting={false}
                                        control={control}      
                                            
                                        disabled              
                                        onValueChange={ (v) => onChange(v.floatValue) }              
                                        //fixedDecimalScale={true} 
                                        thousandSeparator={true}
                                        //{...field}
                                        customInput={TextFieldRight}
                                        defaultValue={value}
                                        value={value}
                                        //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                      />
                                    );
                                  }}
                                  name={`deliveryArticles.${deliveryArticleIndex}.unityAmount`}
                                  //name={`${fieldsName}.${idx}.${cell.id}`}
                                  control={control}
                                />
                              </Box>
                              <Box sx={{ mt: 4, width: '100%' }} >
                                <Button>
                                  {`${t('Add quantity in store or showroom')}`}
                                  <Box sx={{ ...justifyCenter, ml: 1 }}>
                                    <MdOutlineAdd size={24} onClick={handleClickAddStoreQuantity} />
                                  </Box>
                                </Button>
                                  { openStoreQuantityDialog && <FormDialog open={openDeliveryArticle} maxWidth='sm' height='35vh'
                                        okText={t('OK')} cancelText={t('Cancel')} 
                                        title={`${getValues().deliveryArticles[deliveryArticleIndex].articleName}`} 
                                        onCancel={()=> {setOpenStoreQuantityDialog(false);}} 
                                        onClose={()=> {setOpenStoreQuantityDialog(false);}} onOk={handleOkStoreQuantity}  >
                                      <Stack flexDirection='column'>  
                                        <Box sx={{ mt: 0.25, width: '100%' }} > 
                                          <Controller 
                                            name='storeId' control={controlStoreQuantity}                                     
                                            render={ ({field: {onChange, value}}) => (
                                              <TextField select onChange={onChange} value={value} sx={{width:'calc(70% - 8px)'}} id="status"
                                                label={`${t('Store')} - ${t('Showroom')}`} inputProps={ {readOnly: false}} >
                                                {stores && stores.map( 
                                                  (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                                }
                                              </TextField>
                                            )}
                                          />
                                          <Controller                                
                                            render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                              return (
                                                <NumberFormat      
                                                  decimalScale={3}
                                                  label={`${t('Quantity')}`} sx={{width:'calc(30% - 8px)'}}
                                                  allowEmptyFormatting={false}
                                                  control={control}      
                                                               
                                                  onValueChange={ (v) => onChange(v.floatValue) }              
                                                  //fixedDecimalScale={true} 
                                                  thousandSeparator={true}
                                                  //{...field}
                                                  customInput={TextFieldRight}
                                                  defaultValue={value}
                                                  value={value}
                                                  //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                                />
                                              );
                                            }}
                                            name={`quantityDemand`}
                                            //name={`${fieldsName}.${idx}.${cell.id}`}
                                            control={controlStoreQuantity}
                                          />                                                    
                                        </Box>
                                      </Stack>
                                  </FormDialog> }
                              </Box>
                              <Box sx={{ mt: 2, width: '100%' }} 
                                   key={` ${( (deliveryArticleIndex>=0 && getValues().deliveryArticles.length>deliveryArticleIndex ) ?  
                                                getValues().deliveryArticles[deliveryArticleIndex].deliveryArticleStores : [] ).map(x => String(x.storeId)).join('-') }`} >
                                {                             
                                   ( (deliveryArticleIndex>=0 && getValues().deliveryArticles.length>deliveryArticleIndex ) ?  
                                     getValues().deliveryArticles[deliveryArticleIndex].deliveryArticleStores : [] ).map( ( x ,idx) => 
                                    ( <Chip sx={{ ml: 1, mr:1, mb: 1 }}
                                        key={`${idx} - ${x.storeId} - ${x.id}`} label={`${x.storeName} : ${x.quantityDemand}`} color="primary" variant="outlined"
                                        onDelete={() => {removeStoreQuantity(idx)}} 
                                        onClick={(event) => handleClickEditStoreQuantity(event, idx)}/>
                                        ))
                                }
                                
                              </Box>                            

                            </Stack>          
                      </FormDialog> }                      
                    </Box>
                      
                  </Stack> 
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
  )
}
