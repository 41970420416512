
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IContentType, IContentTypeSearch } from "../models/ContentType";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';
//import { IPolicyRisk, IPolicyRiskCoverage } from 'features/contentTypeion/models/Policy';


const _ = () => {

    const axios = useAxios(); 

    const createContentType = async (contentType: IContentType)  =>       
        await (await axios.post('/api/setup/contentType/create', contentType)).data;       
        
    const updateContentType = async (contentType: IContentType)  =>       
        await (await axios.post('/api/setup/contentType/update', contentType)).data; 
    
    const getContentType = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/contentType/get-contentType/${id}`));
      return await data;
    }
   

    const getContentTypes = async (criteria: IContentTypeSearch, pagination?: IPagination) : Promise<IContentType[]> => {

      const {name, description, apiAlias} = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/setup/contentType/get-contentTypes?&name=${name}&description=${description}&apiAlias=${apiAlias}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getContentTypesSearchCount = async (name: string, description: string) : Promise<number> => {
      
      const {data} = (await axios.get(`/api/setup/contentType/get-contentTypes-search-count?name=${name}&description=${description}`));
      return await data;
    }

    const getContentTypesSearch = async (name: string, description: string, criteria: IContentTypeSearch, pagination?: IPagination) : Promise<IContentType[]> => {

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/setup/contentType/get-contentTypes-search?name=${name}&description=${description}`+
                  `&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

        
    return {    
      createContentType,
      updateContentType,
      getContentType,
      getContentTypes,

      getContentTypesSearchCount,
      getContentTypesSearch,

    } 
}

export default _;

export interface IFilterContentTypeOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IContentType[], React.Dispatch<React.SetStateAction<IContentType[]>>],
}

const defaultFilterContentTypeOption: IFilterContentTypeOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterContentType = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IContentType) => void,
                                            filterOption?: IFilterContentTypeOption  ) => {

  const { getContentTypes } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterContentTypeOption;



  const [headContentTypeCells, setHeadContentTypeCells]  = useState<HeadCell<IContentType>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    //{id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', },
    {id:'apiAlias', label : t('API alias'),  display: true, type: 'string', },

    // {id: 'quantityAvailable', label : `${t('Quantity')} : ${t('Available')}`,  display: true && (type === 2), type: 'numeric',},
    // {id: 'quantityStock', label : `${t('Quantity')} : ${t('Stock')}`,  display: true && (type === 2), type: 'numeric',}
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      {name: 'apiAlias', text: t('API alias'), value: ''},
    ]);    

  const [filteredContentTypes, ] = useState<IContentType[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IContentType[]> => {
   
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    const apiAlias = filterElements.find( elt => elt.name === 'apiAlias')?.value || '';

    const arr = await getContentTypes({ name,description, apiAlias}, pagination );
    
    return arr;
  }

  const objKey: keyof IContentType = 'id';

  return {
    title: t('ContentType'), headCells: headContentTypeCells, objKey,
    filterElements, rows: filteredContentTypes, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}


export const useBasicFilterContentTypeSearch = ( getSearch: () => {name: string, description: string}, 
                                            onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IContentType) => void,
                                            filterOption?: IFilterContentTypeOption  ) => {

  const { getContentTypesSearch } = _();

  const { t, i18n } = useTranslation();   
  const {language: lg, applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterContentTypeOption;

  const [headContentTypeCells, setHeadContentTypeCells]  = useState<HeadCell<IContentType>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
   
    {id:'apiAlias', label : t('API alias'),  display: true, type: 'string', },
  ]); 

  const {name, description} = getSearch();

  const [filterElements,] = useState<ITextFilterElement[]>( [         
      
    {name: 'portable', text: t('Portable'), value: ''},
    {name: 'email', text: t('Email'), value: ''},
      
  ]);    

  const [filteredContentTypes, ] = useState<IContentType[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IContentType[]> => {    
    
    const arr = await getContentTypesSearch(name, description, { name: '', description: '', apiAlias: ''}, pagination );
   
    return arr;
  }

  const objKey: keyof IContentType = 'id';

  return {
    title: `${t('ContentType')} : ${name} | ${description} `, headCells: headContentTypeCells, objKey,
    filterElements, rows: filteredContentTypes, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered,
    autoFilter: true
  }
}

