

export interface IStore {
    id: number,
    name: string,
    description: string,
    location: string,
   
    isShowroom: boolean,

    fileName: string,
    base64Picture: string,
  }


  export const defaultStore : IStore = {
    id: 0,
    name: '',
    description: '',
    location: '',

    isShowroom: false,

    fileName: '',
    base64Picture: '',
  }


  export interface IStoreSearch {
    name: string,
    description: string,
    location: string
  }