
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {ISharing, ISharingSearch } from "../models/Sharing";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { currentUserSessionAtom } from 'library/store';

const _ = () => {

    const axios = useAxios(); 

    const createSharing = async (sharing: ISharing)  =>       
        await (await axios.post('/api/setup/sharing/create', sharing)).data;       
        
    const updateSharing = async (sharing: ISharing)  =>       
        await (await axios.post('/api/setup/sharing/update', sharing)).data; 
    
    const getSharing = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/sharing/get-sharing/${id}`));
      return await data;
    }
   

    const getSharings = async (criteria: ISharingSearch) : Promise<ISharing[]> => {

      const {name, description} = criteria;
      const {data} = (await axios.get(`/api/setup/sharing/get-sharings?name=${name}&description=${description}`));
      return await data;
    }
    
    
    return {    
      createSharing,
      updateSharing,
      getSharing,
      getSharings,
      
    } 
}

export default _;

export interface IFilterSharingOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [ISharing[], React.Dispatch<React.SetStateAction<ISharing[]>>],
}

const defaultFilterSharingOption: IFilterSharingOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterSharing = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ISharing) => void,
                                            filterOption?: IFilterSharingOption  ) => {

  const { getSharings } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterSharingOption;



  const [headSharingCells, setHeadSharingCells]  = useState<HeadCell<ISharing>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      
    ]);    

  const [filteredSharings, ] = useState<ISharing[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<ISharing[]> => {
   
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
       
    const arr = await getSharings( { name,description} );
    
    return arr;
  }

  const objKey: keyof ISharing = 'id';

  return {
    title: t('Sharing'), headCells: headSharingCells, objKey,
    filterElements, rows: filteredSharings, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
