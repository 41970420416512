import { IExtension } from "features/configuration/models/ExtensionType";

export interface IContentType {
  id: number,

  name: string,
  description: string,

  apiAlias: string,
  maxContent: number,  

  isProduct: boolean,
  parentContentTypeId: number,
  parentContentTypeName: string,
    
  extensions: IExtension[]
}


export interface IContentTypeSearch {
  name: string,
  description: string,
  apiAlias: string,  
}

export const defaultContentType : IContentType = {
  id: 0,

  name: '',
  description: '',
  apiAlias: '',

  maxContent: 1,  

  isProduct: false,
  parentContentTypeId: 0,
  parentContentTypeName: '',
  
  extensions: []
}
