
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, ElementType}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

//import colorNameList from 'color-name-list';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import QueueIcon from '@mui/icons-material/Queue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import BackpackIcon from '@mui/icons-material/Backpack';

import NotInterestedIcon from '@mui/icons-material/NotInterested';
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';
import EventOutlined from '@mui/icons-material/EventOutlined';
import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined';
import ListOutlined from '@mui/icons-material/ListOutlined';
import Filter1Outlined from '@mui/icons-material/Filter1Outlined';
import DialpadOutlined from '@mui/icons-material/DialpadOutlined';
import VideoLibraryOutlined from '@mui/icons-material/VideoLibraryOutlined';
import ToggleOffOutlined from '@mui/icons-material/ToggleOffOutlined';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';

import { ChromePicker, ColorResult } from 'react-color';



import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom, currentUserSessionAtom } from 'library/store';
import useContentTypeService, { useBasicFilterContentType, useBasicFilterContentTypeSearch } from './services/ContentType';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, Popover, useTheme, SvgIconProps } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IContentType, defaultContentType } from './models/ContentType';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

//import useDeliveryService from 'features/configuration/services/Delivery';

import useUtils from 'library/utils';

import IEnumeration, { Enum_LINE_OF_BUSINESS, Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, 
      Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ,IEnumerationItem, Enum_ARTICLE_WRAPPING } from 'features/configuration/models/Enumeration';

//import {ISharing} from 'features/setup/models/Sharing';
//import useSharingService, { useBasicFilterSharing } from 'features/setup/services/Sharing';

import { IExtension, IExtensionType, _ExtensionType, defaultExtension  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling, carouselImage } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { debounce, sum } from 'lodash';
import { GrClose, GrSearch } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';
import { ExtensionFormDialog } from 'components/ExtensionFormDialog';
//import { IArticleStock } from 'features/configuration/models/Delivery';


type ExtensionUsage = 'content-A' | 'content-U';


const iconFromBaseType = (dataType: _ExtensionType) : ElementType<SvgIconProps> => {
  switch(dataType) {
    case 'boolean': return ToggleOffOutlined;
    case 'date': return EventOutlined;
    case 'decimal': return Filter1Outlined;
    case 'entity': return VideoLibraryOutlined;
    case 'enumeration': return ListOutlined;
    case 'float': return Filter1Outlined;
    case 'integer': return DialpadOutlined;
    case 'text': return DescriptionOutlined;
    case 'time': return AccessTimeOutlined;
    
    default: return DescriptionOutlined;
  }
  
}

export const ContentTypeForm: FC<IContentType> = (props: IContentType = defaultContentType) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { applicationSetup} = useRecoilValue(currentUserSessionAtom);

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  //const {getArticlePerStores } = useDeliveryService();

  const { createContentType, updateContentType, getContentTypesSearch, getContentTypesSearchCount } = useContentTypeService();

  const {capitalizeFirstLetter} = useUtils();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  //const colors = colorNameList.filter(c => c.name.split(' ').length === 1 && c.name.length <= 5).map((color) => color);
  
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterContentType = useBasicFilterContentType( 
    (event: React.MouseEvent<unknown>, row: IContentType) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}

  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );


  const [openParentContentTypeFilter, setOpenParentContentTypeFilter] = useState(false);
  const basicFilterParentContentType = useBasicFilterContentType( 
      async (event: React.MouseEvent<unknown>, row: IContentType) => {
          const {id, name, description} = row;
          
          setValue('parentContentTypeId', id);
          setValue('parentContentTypeName', name);
          //setValue('contentTypeDescription', description);
                
          setOpenParentContentTypeFilter(false);
      }
  );
  

  const methods = useForm<IContentType>({defaultValues: {...defaultContentType, }});

  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  let { fields, append: appendExtensions, update: updateExtension ,remove: removeExtension,  } = useFieldArray({
    name: `extensions`,
    control,            
  });

  //const watchBase64Picture = watch('base64Picture');
  
  const watchName = watch('name');    
  const watchDescription = watch('description');    

  const watchParentContentTypeId = watch('parentContentTypeId');

  const [openContentTypeSearchFilter, setOpenContentTypeSearchFilter] = useState(false);
  const basicFilterContentTypeSearch = useBasicFilterContentTypeSearch( 
      () => {
        const { name, description} = getValues();

        return {name, description};
      },      
      (event: React.MouseEvent<unknown>, row: IContentType) => {
        const {id, name, description} = row;
        
        _setId(row.id);
        setValue('id', id);
        setValue('name', name);
        setValue('description', description); 

        setOpenContentTypeSearchFilter(false);
      }
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [color, setColor] = useState<string>('#000');

  // const watchParentContentTypeId = watch('parentContentTypeId');

  // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IContentType>,Error,IContentType>(
      _id>0?updateContentType:createContentType, {   
        onSuccess: (data: IResult<IContentType>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('ContentType')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['ContentType',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IContentType>(['ContentType', _id], () => retrieveEntity('ContentType',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      // const {data: enumItems} = useQuery<IEnumerationItem[]>(
      //   ['EnumerationItems', 'ContentType'], () => 
      //     getEnumerationItemsByEnumerationCodes( [Enum_LINE_OF_BUSINESS, 
      //         Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, Enum_ARTICLE_WRAPPING,
      //         Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ] ));
 
    const handleClickOpenParentContentType = async (event: any) => {   
      setOpenParentContentTypeFilter(true);
    }

    const handleClickRemoveParentContentType = async (event: any) => {
      setValue('parentContentTypeId', 0);
      setValue('parentContentTypeName','');
    }


    const { control: controlExtension, setValue: setValueExtension, getValues: getValuesExtension, reset: resetExtension } = useForm<IExtension>({defaultValues:defaultExtension});
    const [extensionUsage, setExtensionUsage] = useState<ExtensionUsage>('content-A');
  
    const [extensionIndex, setExtensionIndex] = useState<number>(-1);
    const [openExtension, setOpenExtension] = useState(false);
    const handleClickAddExtension = (event: any, extUsage: ExtensionUsage) => {
      
      setExtensionUsage(extUsage);

      resetExtension({...defaultExtension});

      // setValueExtension('alias', '');
      // setValueExtension('description', '');
      // setValueExtension('extensionTypeId', 0);
      // setValueExtension('extensionTypeName', '');
      // setValueExtension('extensionTypeType', 'text');
      // setValueExtension('extensionTypeBaseType', 'string');
      
      setOpenExtension(true);
    }

    const handleClickEditExtension = (event: any, index: number, extUsage: ExtensionUsage) => {
      
      const extension = getValues().extensions.at(index);
      if(isFalsy(extension)) return;     
      
      const {alias, description, extensionTypeId, extensionTypeName, type, extensionTypeType, extensionTypeBaseType} = extension!;
      // resetExtension({...getValuesExtension(), alias, description, extensionTypeId, extensionTypeName,
      //   extensionTypeType, extensionTypeBaseType });
     resetExtension({...extension! });

      setExtensionUsage(extUsage);
      setExtensionIndex(index); 
            
      setOpenExtension(true);
    }
  
    const handleOkExtension =  (event : MouseEvent<HTMLButtonElement>) => {
      const { alias, description, extensionTypeId, extensionTypeName, extensionTypeType, extensionTypeBaseType } = getValuesExtension();
        
      if(isFalsy(alias) || isFalsy(description) || isFalsy(extensionTypeId)) return;
  
      if(extensionUsage === 'content-A') {
        if(getValues().extensions.some(ext => ext.alias === alias)) return;
        
        appendExtensions({ id: 0, alias, description, extensionTypeId, extensionTypeName, type: 'contentType', 
          extensionTypeType, extensionTypeBaseType});

      }      
      else if(extensionUsage === 'content-U') {
        
        const extension = getValues().extensions.at(extensionIndex);
        if(isFalsy(extension)) return;
        
        const findIdx = getValues().extensions.findIndex( ext => ext.alias === alias);
        if(  findIdx !== extensionIndex && findIdx >= 0 ) return;
        
        updateExtension(extensionIndex, {...extension!, alias, description, extensionTypeId, extensionTypeName,
               extensionTypeType, extensionTypeBaseType});
      } 

      setOpenExtension(false);    
    }

    const [openExtensionProps, setOpenExtensionProps] = useState(false);
    const handleClickOpenExtensionProps = (event: MouseEvent<SVGSVGElement>, index: number) => {
      setOpenExtensionProps(true);
    }

function openFileDialog() {
  (document as any).getElementById("file-upload").click();
}

const setFile = (_event: any) => {
  let f = _event.target.files![0];

  const fileSizeInKB = f.size / 1024;
  // Check if the file size is within your limit (e.g., 200 KB)
  if (fileSizeInKB > 200) {
    alert(t('File size should be less than 200 KB'));
    return;
  }

  var reader = new FileReader();

  reader.onload = function () {

      var binaryString = reader.result as string;
  
      const base64String = binaryString
                                  .replace('data:', '')
                                  .replace(/^.+,/, '');

      // setValue("base64Picture", base64String);
      // setValue("fileName", f.name);
    };

    reader.onerror = function () {
      console.log("File load failed");
    };    
    reader.readAsDataURL(f);    
};

const debouncedNameChange = useRef(
  debounce( async () => {      
    const { id, name, description} = getValues();

    if(id>0) return;

    if( (isFalsy(name) || name.trim() === '') && (isFalsy(description) || description.trim() === '' )) return;

    const count = await getContentTypesSearchCount(name, description);

    if(!isFalsy(refContentTypeSnackbarId.current)) closeSnackbar(refContentTypeSnackbarId.current!);
    
    if(count > 0) 
      refContentTypeSnackbarId.current = enqueueSnackbar( `${t('ContentType')} --> ${t('You have to check if product exists before save, verify it in this list of')} : ${count} ${t('_product')}(s)`, { variant: 'warning',
            anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
            action: xActionContentTypeSearch() } );             

  }, 2500)
).current;

const refContentTypeSnackbarId = useRef<SnackbarKey>();

const xActionContentTypeSearch = (): SnackbarAction => (snackbarId: SnackbarKey) => (
  <>        
      <Button onClick={() => { 
                           
              setOpenContentTypeSearchFilter(true);
              closeSnackbar(snackbarId); }}>   
          <GrSearch size={24} />
      </Button>
      <Button onClick={() => { closeSnackbar(snackbarId) }}>
          <GrClose size={24} />
      </Button>
  </>
)

  useEffect( () => {     
          
    debouncedNameChange();      
    }, [watchName, watchDescription, debouncedNameChange]);

    const refEnumItems = useRef<IEnumerationItem[]>();    
    // useEffect( () => {   
    //     refEnumItems.current = enumItems;
      
    // }, [enumItems])

    // const [articleStocks, setArticleStocks] = useState<IArticleStock[]>([]);
    // useEffect( () => {
      
    //   async function _() {   
    //     if(_data && _data.type === 'article' && _data.id > 0) {         
    //       const stocks = await getArticlePerStores( 0, _id);
    //       setArticleStocks(stocks);
    //     }
    //   }
    //   _();  
    // }, [_data] );

      useEffect( () => {        
        setCurrentFormNameAtom(t('ContentType'));
        setCurrentBasicTextFilterProps(basicFilterContentType);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            setCurrentFormNameAtom(_id>0?`${t('ContentType')} - # ${_id} # -`: t('ContentType') );
            if(_id > 0)
              retrieveData('ContentType',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);


        useEffect( () => {            
          if(openExtensionProps) {
              setOpenExtension(false);
          }

          }, [openExtensionProps]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset({...defaultContentType });    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('ContentType', _id)) {
          setIsSaveLoading(false);
             return;
        }          
  
        const data = getValues(); 

        console.log({data});

        // if( isFalsy(data.lineOfBusinessCode) || data.lineOfBusinessCode.trim() === '') {
        //   enqueueSnackbar( t('Line of business is not specified'), { variant: 'warning',
        //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        //   setIsSaveLoading(false);
        //   return;
        // }
        
        if(data.name.trim() === '' || data.description.trim() === '') {
            enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }

        // const taskCodes = data.serviceTasks.map(x => x.taskCode);
        // if( Array.from(new Set(taskCodes)).length !== data.serviceTasks.length) {
        //   enqueueSnackbar( t('Tasks are not well defined'), { variant: 'warning',
        //     anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        //   setIsSaveLoading(false);
        //   return;
        // }
    
        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('ContentType', _id);
      }

    
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                                
                                <TextField sx={{width:'calc(50% - 8px)'}} id="name" label={t('Name')} {...register('name')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  />   
                                <TextField sx={{width:'calc(50% - 8px)'}} id="name" label={t('API alias')} {...register('apiAlias')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />    
                                { openContentTypeSearchFilter && <FormDialog open={openContentTypeSearchFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('ContentType')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenContentTypeSearchFilter(false);}} onOk={()=> {setOpenContentTypeSearchFilter(false);}}  >
                                      <BasicTextFilterForm<IContentType> {...basicFilterContentTypeSearch } />
                              </FormDialog> }                                                  
                            </Box>   
                                                        
                                                    
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(75% - 8px)'}} id="description" label={t('Description')} {...register('description')} /> 
                              <Controller                                
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat      
                                      //decimalScale={2}
                                      label={t('Cardinality')} sx={{width:'calc(25% - 8px)'}}
                                      allowEmptyFormatting={false}
                                      control={control}             
                                                   
                                      //fixedDecimalScale={true} 
                                      thousandSeparator={true}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      //{...field}
                                      customInput={TextFieldRight}
                                      //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                    />
                                  );
                                }}
                                name={`maxContent`}
                                //name={`${fieldsName}.${idx}.${cell.id}`}
                                control={control}
                              />                     
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(60% - 8px)'}} id="name" label={`${t('Content type')} - ${t('Parent')}`} 
                                {...register('parentContentTypeName')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">         
                                      { (isFalsy(watchParentContentTypeId) ||  watchParentContentTypeId<=0) ? 
                                          <IconButton color="primary" onClick={handleClickOpenParentContentType}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton>   :
                                          <IconButton color="primary" onClick={handleClickRemoveParentContentType}>
                                            <RemoveCircleIcon />
                                          </IconButton>
                                      }                                                                                            
                                  </InputAdornment>
                                )
                              }} />
                              <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                label={`${t('Product')} ?`}
                                control={
                                  <Controller
                                    name={`isProduct`}
                                    control={control}
                                    render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                  />} />
                                { openParentContentTypeFilter && <FormDialog open={openParentContentTypeFilter} maxWidth='md' 
                                okText={t('OK')} cancelText='' title={`${t('ContentType')}...`} onCancel={()=> {}} 
                                onClose={()=> {setOpenParentContentTypeFilter(false);}} onOk={()=> {setOpenParentContentTypeFilter(false);}}  >
                                    <BasicTextFilterForm<IContentType> {...basicFilterParentContentType } />
                            </FormDialog> } 
                            </Box>
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>      
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Button>
                            {`${t('Add property')}`}
                            <Box sx={{ ...justifyCenter, ml: 1 }}>
                              <MdOutlineAdd size={24} onClick={(event: any) => handleClickAddExtension(event, 'content-A') } />
                            </Box>
                          </Button>
                            { openExtension && <ExtensionFormDialog open={openExtension} maxWidth='sm' height='50vh'
                                control={controlExtension} setValue={setValueExtension}
                                okText={t('OK')} cancelText='' title={t('Extension')} onCancel={()=> {}} 
                                onClose={()=> {setOpenExtension(false);}} onOk={handleOkExtension}  /> }
                            { extensionIndex>=0 && extensionIndex < getValues().extensions.length && openExtensionProps &&
                              <FormDialog open={openExtensionProps} maxWidth='xs' height='50vh'
                                  okText='' cancelText='' title={getValues().extensions[extensionIndex].description} onCancel={()=> {}} 
                                  onClose={()=> {setOpenExtensionProps(false);}} onOk={()=> {setOpenExtensionProps(false);}}  >
                                  <Stack flexDirection='column'>      
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                        label={`${t('Is required ?')}`}
                                        control={
                                          <Controller
                                            name={`extensions.${extensionIndex}.isRequired`}
                                            control={control}
                                            render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} />
                                      
                                    </Box>  
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                        label={`${t('Is unique ?')}`}
                                        control={
                                          <Controller
                                            name={`extensions.${extensionIndex}.isUniqueField`}
                                            control={control}
                                            render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                        label={`${t('Is private ?')}`}
                                        control={
                                          <Controller
                                            name={`extensions.${extensionIndex}.isPrivateField`}
                                            control={control}
                                            render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} />                                      
                                    </Box> 
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                        label={`${t('Main info ?')}`}
                                        control={
                                          <Controller
                                            name={`extensions.${extensionIndex}.isMainField`}
                                            control={control}
                                            render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} />
                                    </Box>
                                  </Stack> 
                              </FormDialog> }
                        </Box>    
                        <Box sx={{ mt: 1, width: '100%' }} >
                          {                              
                              getValues().extensions.map( ( {alias, description, extensionTypeType} ,idx) => {
                                const IconComponent = iconFromBaseType(extensionTypeType);
                                
                                return ( <Chip sx={{ ml: 1, mr:1, mb: 1 }}
                                    icon={ <IconComponent onClick={(event) => handleClickOpenExtensionProps(event, idx)} />}
                                    key={`${idx} - ${alias}`} label={`${alias} : ${description} `} color="primary" variant="outlined"
                                    onDelete={() => {removeExtension(idx)}} 
                                    onClick={(event) => handleClickEditExtension(event, idx, 'content-U')}/>
                                    )
                                } )
                            }
                        </Box>                 
                        
                      </Stack>
                    </Grid>                       
                </Grid>
            </Box>
        </FormProvider> 
  )
}

