
export type PersonType = 'physical' | 'moral';

export interface IPerson {
    id: number,
    defaultLanguage: string,
    nationality: string,
    identificationType: string,
    identificationNumber: string, 
    identificationDelivrancePlace: string, 
    identificationStartDate: Date, 
    identificationEndDate: Date,

    type: PersonType,
        
    firstName: string,
    lastName: string,
    particularSign: string,
    birthDateType: 'date'| 'day' | 'none',
    birthDate: Date,
    birthPlace: string,
    gender: string,
    civility: string,

    corporationName: string,
    corporationType: string,
    creationDate: Date,
    creationDateType: 'date'| 'day' | 'none',

    country: string,
    town: string,

    portable1: string,
    portable2: string,
    email1: string,
    email2: string,  
    addressLine1: string,
    addressLine2: string,
    
    profession: string,
    activity: string,

    studentCode: string,

    fatherId: number,
    fatherFirstName: string,
    fatherLastName: string,
    fatherParticularSign: string,
    fatherBirthDate: Date,
    fatherBirthPlace: string,
    fatherGender: string,
    fatherCivility: string,
    fatherPortable1: string,
    fatherPortable2: string,
    fatherEmail1: string,
    fatherEmail2: string,
    fatherCountry: string,
    fatherTown: string,
    fatherAddressLine1: string,
    fatherAddressLine2: string,

    motherId: number,
    motherFirstName: string,
    motherLastName: string,
    motherParticularSign: string,
    motherBirthDate: Date,
    motherBirthPlace: string,
    motherGender: string,
    motherCivility: string,
    motherPortable1: string,
    motherPortable2: string,
    motherEmail1: string,
    motherEmail2: string,
    motherCountry: string,
    motherTown: string,
    motherAddressLine1: string,
    motherAddressLine2: string,

    tutorId: number,
    tutorFirstName: string,
    tutorLastName: string,
    tutorParticularSign: string,
    tutorBirthDate: Date,
    tutorBirthPlace: string,
    tutorGender: string,
    tutorCivility: string,
    tutorPortable1: string,
    tutorPortable2: string,
    tutorEmail1: string,
    tutorEmail2: string,
    tutorCountry: string,
    tutorTown: string,
    tutorAddressLine1: string,
    tutorAddressLine2: string,


    fileName: string,
    base64Picture: string,

    portable: string,

    clientOfEmployeeId: number,
    clientOfEmployeeFullName: string,

    newClientOfEmployeeId: number,    
    newClientOfEmployeeFullName: string,    
}



export const defaultPerson : IPerson = {

    id: 0,
    defaultLanguage: '',
    nationality: '',
    identificationType: '',
    identificationNumber: '', 
    identificationDelivrancePlace: '', 
    identificationStartDate: new Date(), 
    identificationEndDate: new Date(),


    type: 'physical',
    
    firstName: '',
    lastName: '',
    particularSign: '',
    birthDateType: 'date',
    birthDate: new Date(),
    birthPlace: '',
    gender: '',
    civility: '',

    corporationName: '',
    corporationType: '',
    creationDate: new Date(),
    creationDateType: 'date',

    country: '',
    town: '',

    portable1: '',
    portable2: '',
    email1: '',
    email2: '',  
    addressLine1: '',
    addressLine2: '',
    
    profession: '',
    activity: '',

    studentCode: '',

    fatherId: 0,
    fatherFirstName: '',
    fatherLastName: '',
    fatherParticularSign: '',
    fatherBirthDate: new Date(),
    fatherBirthPlace: '',
    fatherGender: '',
    fatherCivility: '',
    fatherPortable1: '',
    fatherPortable2: '',
    fatherEmail1: '',
    fatherEmail2: '',
    fatherCountry: '',
    fatherTown: '',
    fatherAddressLine1: '',
    fatherAddressLine2: '',

    motherId: 0,
    motherFirstName: '',
    motherLastName: '',
    motherParticularSign: '',
    motherBirthDate: new Date(),
    motherBirthPlace: '',
    motherGender: '',
    motherCivility: '',
    motherPortable1: '',
    motherPortable2: '',
    motherEmail1: '',
    motherEmail2: '',
    motherCountry: '',
    motherTown: '',
    motherAddressLine1: '',
    motherAddressLine2: '',

    tutorId: 0,
    tutorFirstName: '',
    tutorLastName: '',
    tutorParticularSign: '',
    tutorBirthDate: new Date(),
    tutorBirthPlace: '',
    tutorGender: '',
    tutorCivility: '',
    tutorPortable1: '',
    tutorPortable2: '',
    tutorEmail1: '',
    tutorEmail2: '',
    tutorCountry: '',
    tutorTown: '',
    tutorAddressLine1: '',
    tutorAddressLine2: '',

    fileName: '',
    base64Picture: '',

    portable: '',

    clientOfEmployeeId: 0,
    clientOfEmployeeFullName: '',
    newClientOfEmployeeId: 0,
    newClientOfEmployeeFullName: ''
}

export interface IPersonSearch {
    name: string,
    portable: string,
    email: string    
  }

  export interface IPersonFinancialSituation {
    activePointAmount: number, 
    activePaymentAmount: number, 
    totalAmount: number, 
    unpaidAmount: number
  }

export const defaultPersonFinancialSituation : IPersonFinancialSituation = {
    activePointAmount: 0, 
    activePaymentAmount: 0, 
    totalAmount: 0, 
    unpaidAmount: 0
}