import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { HeadCell } from 'components/ui/EnhancedTable';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useAxios from 'library/axios'; 

import { IOnlineCustomer, IOnlineCustomerValidationCode, IOnlineCustomerSearch } from '../models/OnlineCustomer';

import { globalConfig } from 'config';


const _ = () => {

    
    const axios = useAxios();

        
    const getOnlineCustomers = async (criteria: IOnlineCustomerSearch, pagination?: IPagination) : Promise<IOnlineCustomer[]> => {

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const { firstName, lastName, userName, email, portable} = criteria;
  
        const {data} = (await axios.get(`/api/security/onlineCustomer/get-online-customers?userName=${userName}&firstName=${firstName}&lastName=${lastName}&email=${email}&portable=${portable}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
        return await data;
      }

      const getOnlineCustomerValidationCodes = async (criteria: IOnlineCustomerSearch) : Promise<IOnlineCustomerValidationCode[]> => {

        const { firstName, lastName, userName, email, portable} = criteria;
  
        const {data} = (await axios.get(`/api/security/group/get-groups?firstName=${firstName}&lastName=${lastName}`));
        return await data;
      }      

    return {
        getOnlineCustomers,
        getOnlineCustomerValidationCodes
        
    } 
}

export default _;

export const useBasicFilterOnlineCustomer = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IOnlineCustomer) => void  ) => {

  const { getOnlineCustomers } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();

  const [headOnlineCustomerCells, setHeadOnlineCustomerCells]  = useState<HeadCell<IOnlineCustomer>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'userName', label : t('User name'),  display: true, type: 'string', },
    {id:'firstName', label : t('First name'),  display: true, type: 'string', },
    {id:'lastName', label : t('Last name'),  display: true, type: 'string', },
  ]); 
  const [filterElements,] = useState( [       
      {name: 'userName', text: t('User name'), value: ''}, 
      {name: 'firstName', text: t('First name'), value: ''},     
      {name: 'lastName', text: t('Last name'), value: ''},    
      {name: 'email', text: t('Email'), value: ''},    
      {name: 'portable', text: t('Portable'), value: ''},    
    ]);    

  const [filteredOnlineCustomers, ] = useState<IOnlineCustomer[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination): Promise<IOnlineCustomer[]> => {
            
    const userName = filterElements.find( elt => elt.name === 'userName')?.value || '';
    const firstName = filterElements.find( elt => elt.name === 'firstName')?.value || '';
    const lastName = filterElements.find( elt => elt.name === 'lastName')?.value || '';
    const email = filterElements.find( elt => elt.name === 'email')?.value || '';
    const portable = filterElements.find( elt => elt.name === 'portable')?.value || '';
    
    const arr = await getOnlineCustomers( {userName, firstName, lastName, email, portable}, pagination );
    
    return arr;
  }

  const objKey: keyof IOnlineCustomer = 'id';

  return {
    title: ` ${t('Customer')}(s) `, headCells: headOnlineCustomerCells, objKey,
    filterElements, rows: filteredOnlineCustomers, 
    onFilterButtonClick, onRowDoubleClick
  }
}