import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { ElementType, useState } from 'react';
import { _ExtensionType, IExtensionType,  IExtensionTypeSearch, BaseType, IExtension, IExtensionValue } from "../models/ExtensionType";
import { useTranslation  } from 'react-i18next';
import { IPagination,ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useNavigate } from 'react-router-dom';



const _ = () => {

    const axios = useAxios();     

    const createExtensionType = async (extensionType: IExtensionType)  =>       
        await (await axios.post('/api/configuration/extensionType/create', extensionType)).data;       
        
    const updateExtensionType = async (extensionType: IExtensionType)  =>       
        await (await axios.post('/api/configuration/extensionType/update', extensionType)).data; 
    
    const getExtensionType = async (id  : number )  => {
      const {data} = (await axios.get(`/api/configuration/extensionType/get-extensionType/${id}`));
      return await data;
    }

    const getExtensionTypes = async (criteria: IExtensionTypeSearch, pagination?: IPagination) : Promise<IExtensionType[]> => {
    const {name, description} = criteria;

    const pageSize = pagination?.pageSize ?? 50;
    const pageNumber = pagination?.pageNumber ?? 1;

    const {data} = (await axios.get(`/api/configuration/extensionType/get-extensionTypes?name=${name}&description=${description}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
    return await data;
    }

    const defaultValue = (dataType: _ExtensionType): any => {
      switch(dataType) {
        case 'boolean': return false;
        case 'date': return new Date();
        case 'decimal': return 0;
        case 'entity': return 0;
        case 'enumeration': return '';
        case 'float': return 0;
        case 'integer': return 0;
        case 'text': return '';
        case 'time': return new Date();

        
        default: return null;
      }
    }

    const baseType = (dataType : string | undefined) : BaseType => {

      switch(dataType) {
        case 'boolean': return 'boolean';
        case 'date': return 'date';
        case 'decimal': return 'numeric';
        case 'entity': return 'numeric';
        case 'enumeration': return 'string';
        case 'float': return 'numeric';
        case 'integer': return 'numeric';
        case 'text': return 'string';
        case 'time': return 'time';

        case 'numeric': return 'numeric';
        default: return 'string';
      }
    }

    const valueType = (value: string, dataType : _ExtensionType) : any => {

      switch(dataType) {
        case 'boolean': return Boolean(value);
        case 'date': return Date.parse( String(value) );
        case 'decimal': return Number(value);
        case 'entity': return Number(value);
        case 'enumeration': return String(value);
        case 'float': return Number(value);
        case 'integer': return Number(value);
        case 'text': return String(value);
        case 'time': return Date.parse( String(value) );
        default: return String(value);
      }      
    }

    const valueString = (extensionValue: IExtensionValue) : string => {
      
      const {extensionTypeType,
            textValue, integerValue, decimalValue, floatValue, 
            dateValue, booleanValue, timeValue, enumerationItemCode, entityId} 
              = extensionValue;
      switch(extensionTypeType) {
        case 'boolean': return String(booleanValue);
        case 'date': return String(dateValue);
        case 'decimal': return String(decimalValue);
        case 'entity': return String(entityId);
        case 'enumeration': return String(enumerationItemCode);
        case 'float': return String(floatValue);
        case 'integer': return String(integerValue);
        case 'text': return String(textValue);
        case 'time': return String(timeValue);
        default: return String(textValue);
      }
    }

    
      
    return {    
      createExtensionType,
      updateExtensionType,

      getExtensionType,
      getExtensionTypes,

      defaultValue,
      baseType,
      valueType,
      
      valueString
      
    } 
}

export default _;


export const useBasicFilterExtensionType = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IExtensionType) => void  ) => {

  const { getExtensionTypes } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();

  

  const [headExtensionTypeCells, setHeadExtensionTypeCells]  = useState<HeadCell<IExtensionType>[]>([
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },

  ]); 

  const [filterElements, ] = useState([       
        {name: 'name', text: t('Name'), value: ''}, 
        {name: 'description', text: t('Description'), value: ''},       
      ]);

  const [filteredExtensionTypes, ] = useState<IExtensionType[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination): Promise<IExtensionType[]> => {
            
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    
    const arr = await getExtensionTypes( {name, description}, pagination );
    
    return arr;
  }

  const objKey: keyof IExtensionType = 'id';

  return {
    title: t('Extension type'), headCells: headExtensionTypeCells, objKey,
    filterElements, rows: filteredExtensionTypes, 
    onFilterButtonClick, onRowDoubleClick
  }
}

