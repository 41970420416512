
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

//import colorNameList from 'color-name-list';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import QueueIcon from '@mui/icons-material/Queue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import BackpackIcon from '@mui/icons-material/Backpack';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';

import { ChromePicker, ColorResult } from 'react-color';



import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom, currentUserSessionAtom } from 'library/store';
import useMediaService, { useBasicFilterMedia } from './services/Media';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, Popover, useTheme } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IMedia, MediaType, defaultMedia } from './models/Media';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

//import useDeliveryService from 'features/configuration/services/Delivery';

import useUtils from 'library/utils';

import IEnumeration, { Enum_LINE_OF_BUSINESS, Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, 
      Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ,IEnumerationItem, Enum_ARTICLE_WRAPPING } from 'features/configuration/models/Enumeration';

//import {ISharing} from 'features/setup/models/Sharing';
//import useSharingService, { useBasicFilterSharing } from 'features/setup/services/Sharing';

import { IExtension, IExtensionType, defaultExtension  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling, carouselImage } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { debounce, sum } from 'lodash';
import { GrClose, GrSearch } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';
import { ExtensionFormDialog } from 'components/ExtensionFormDialog';
//import { IArticleStock } from 'features/configuration/models/Delivery';


type ExtensionUsage = 'content-A' | 'content-U';

export const MediaForm: FC<IMedia> = (props: IMedia = defaultMedia) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { applicationSetup} = useRecoilValue(currentUserSessionAtom);

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  //const {getArticlePerStores } = useDeliveryService();

  const { createMedia, updateMedia } = useMediaService();

  const {capitalizeFirstLetter} = useUtils();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  //const colors = colorNameList.filter(c => c.name.split(' ').length === 1 && c.name.length <= 5).map((color) => color);
  
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterMedia = useBasicFilterMedia( 
    (event: React.MouseEvent<unknown>, row: IMedia) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}

  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );


  

  const methods = useForm<IMedia>({defaultValues: {...defaultMedia, }});

  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

 

  const watchBase64File = watch('base64File');
  const watchFileName = watch('fileName');
  
  const watchName = watch('name');    
  const watchDescription = watch('description');    
  const watchType = watch('type');    
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [color, setColor] = useState<string>('#000');

  // const watchParentMediaId = watch('parentMediaId');

  // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IMedia>,Error,IMedia>(
      _id>0?updateMedia:createMedia, {   
        onSuccess: (data: IResult<IMedia>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Media')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Media',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IMedia>(['Media', _id], () => retrieveEntity('Media',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      // const {data: enumItems} = useQuery<IEnumerationItem[]>(
      //   ['EnumerationItems', 'Media'], () => 
      //     getEnumerationItemsByEnumerationCodes( [Enum_LINE_OF_BUSINESS, 
      //         Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, Enum_ARTICLE_WRAPPING,
      //         Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ] ));
 
  const handleType = ( event: React.MouseEvent<HTMLElement>, newMediaType: MediaType ) => {

    if(newMediaType === null) return;
    setValue('type', newMediaType);

  };
    
function openFileDialog() {
  (document as any).getElementById("file-upload").click();
}

const setFile = (_event: any) => {
  let f = _event.target.files![0];

  const fileSizeInKB = f.size / 1024;
  // Check if the file size is within your limit (e.g., 200 KB)
  if (fileSizeInKB > 200) {
    alert(t('File size should be less than 200 KB'));
    return;
  }

  var reader = new FileReader();

  reader.onload = function () {

      var binaryString = reader.result as string;
  
      const base64String = binaryString
                                  .replace('data:', '')
                                  .replace(/^.+,/, '');

      setValue("base64File", base64String);
      setValue("fileName", f.name);
    };

    reader.onerror = function () {
      console.log("File load failed");
    };    
    reader.readAsDataURL(f);    
};



    const refEnumItems = useRef<IEnumerationItem[]>();    
    // useEffect( () => {   
    //     refEnumItems.current = enumItems;
      
    // }, [enumItems])

    // const [articleStocks, setArticleStocks] = useState<IArticleStock[]>([]);
    // useEffect( () => {
      
    //   async function _() {   
    //     if(_data && _data.type === 'article' && _data.id > 0) {         
    //       const stocks = await getArticlePerStores( 0, _id);
    //       setArticleStocks(stocks);
    //     }
    //   }
    //   _();  
    // }, [_data] );

      useEffect( () => {        
        setCurrentFormNameAtom(t('Media'));
        setCurrentBasicTextFilterProps(basicFilterMedia);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            setCurrentFormNameAtom(_id>0?`${t('Media')} - # ${_id} # -`: t('Media') );
            if(_id > 0)
              retrieveData('Media',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset({...defaultMedia });    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('Media', _id)) {
          setIsSaveLoading(false);
             return;
        }          
  
        const data = getValues(); 

        // if( isFalsy(data.lineOfBusinessCode) || data.lineOfBusinessCode.trim() === '') {
        //   enqueueSnackbar( t('Line of business is not specified'), { variant: 'warning',
        //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        //   setIsSaveLoading(false);
        //   return;
        // }
        
        if(data.name.trim() === '' || data.description.trim() === '') {
            enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }

        // const taskCodes = data.serviceTasks.map(x => x.taskCode);
        // if( Array.from(new Set(taskCodes)).length !== data.serviceTasks.length) {
        //   enqueueSnackbar( t('Tasks are not well defined'), { variant: 'warning',
        //     anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        //   setIsSaveLoading(false);
        //   return;
        // }
    
        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Media', _id);
      }

    
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <ToggleButtonGroup value={watchType} exclusive key={watchType} size="small" onChange={handleType} aria-label="text alignment" fullWidth >
                              <ToggleButton value="url" aria-label="centered">
                                {t('Url')}
                              </ToggleButton>
                              <ToggleButton value="file" aria-label="centered">
                                {t('File')}
                              </ToggleButton> 
                              
                            </ToggleButtonGroup>                                                             
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                              
                              <TextField sx={{width:'calc(60% - 8px)'}} id="name" label={t('Name')} {...register('name')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  />
                                  
                                                                                
                          </Box>   
                                            
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')} />                                                 
                          </Box>
                          {watchType === 'url' && <Box sx={{ mt: 1, width: '100%' }} >
                            <TextField sx={{width:'calc(100% - 8px)'}} id="url" label={t('Url')} {...register('url')} 
                              inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>                                                 
                          </Box> }
                          {watchType === 'file' && <Box sx={{ mt: 1, width: '100%' }} >
                            <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}
                              // accept="image/*" 
                              />
                            <Button id='btnAfterAction' onClick={openFileDialog}>
                              {isFalsy(watchFileName) ? `${t('Click to toad file')} ...` : `${watchFileName} -> ${t('Click to change')}`}
                            </Button>  
                            <div key={'step.id'}>
                              <Box
                                component="img"
                                sx={carouselImage}
                                //src={`data:image/png;base64,${watchBase64Picture}`}
                                src={isFalsy(watchBase64File)?`/images/product.JPG`:`data:image/png;base64,${watchBase64File}`}
                                onClick={openFileDialog}
                                //alt={step.id}
                              ></Box>
                            </div>
                          </Box> }  
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>      
                           
                                        
                        
                      </Stack>
                    </Grid>                       
                </Grid>
            </Box>
        </FormProvider> 
  )
}

