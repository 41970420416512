
export type MediaType = 'url' | 'file';

export interface IMedia {
  id: number,

  name: string,
  description: string,

  type: MediaType,

  url: string,
  fileName: string,  
  base64File: string, 
}


export interface IMediaSearch {
  name: string,
  description: string,
  fileName: string,  
  url: string
}

export const defaultMedia : IMedia = {
  id: 0,

  name: '',
  description: '',
  
  type: 'url',

  url: '',
  fileName: '',  
  base64File: '', 
}