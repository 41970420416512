import { PersonType } from "features/production/models/Person";


export type BillingDetailType = 'service' | 'article' | 'packaging' | 'formation' | 'maintenance' | 'contract' | 'fee';

export type BillingPackagingDetailType = 'service' | 'article';

export type BillingServiceTaskType = 'service-task' | 'packaging-task';

export interface IAmount {type: string, amount: number, amountMonth: number}


export interface IBilling {
    id: number,

    onlineCustomerId: number,
    businessEmployeeId: number,

    issueDate: Date,    
    reference: string,

    showroomId: number,

    // isObligatory: boolean,
    // isCancelled: boolean,
    // cancellationPurpose: string,
    // cancellationDate: Date,    

        
    // billingTypeName: string,
    // billingCategoryName: string,

    netAmount: number,
    tax: number,
    amount: number,    
    amountPaid: number, 
    unpaidAmount: number, 

    commissionAmount: number,
    
    //dueAmount: number,   

    customerPersonType: PersonType,
    customerFirstName: string,
    customerLastName: string,
    customerBirthDateType: 'date' | 'day' | 'none',
    customerBirthDate: Date,
    customerParticularSign: string, 

    customerCorporationName: string,
    customerCorporationType: string,
    customerCreationDateType: 'date' | 'day' | 'none',
    customerCreationDate: Date,

    customerPortable1: string,
    customerPortable2: string,
    customerEmail1: string,
    customerEmail2: string, 

    status: string,
    statusDate: Date
    statusPurpose: string,

    businessEmployeeFullName: string,
    businessEmployeeManagerFullName: string,

    proformaNumber: string,
    billingNumber: string,

    billingDetails: IBillingDetail[],

    serviceDuplicateCount: number,

    shippingAddressLine1: string,
    shippingAddressLine2: string,
    shippingAddressCity: string,
    shippingAddressState: string,
    shippingAddressPostalCode: string,
    shippingAddressCountryCode: string,
    shippingAddressName: string,

    billingShippingAddresses: IBillingShippingAddress[]
  }

  export interface IBillingDetail {
    id: number,
    billingId: number,

    baseAmount: number,
    discount: number,
    loaded: number,
    pricePurpose: string,
    netAmount: number,
    tax: number,
    amount: number,
    
    isDelivered: boolean,
    deliveryDate: Date,
    isDeliveryDateDefine: boolean,
  
    type: BillingDetailType,

    articleId: number,
    quantity: number,
    articleOption: string,
    unityAmount: number,
    serviceId: number,
    personId: number,
    packagingId: number,
    formationId: number,
    studentId: number,

    maintenanceId: number,
    maintenanceCategory: string,
    maintenanceReference: string,
    maintenanceDescription: string,
    maintenanceStartDate: Date,
    maintenanceEndDate: Date,
    contractId: number,
    contractScope: string,
    contractType: string,
    contractDescription: string,
    contractEffectiveDate: Date,
    contractExpirationDate: Date,

    maxDiscountAmount: number,
    maxLoadedAmount: number,
    isPriceDpendsOnQuantity: boolean,

    productName: string,
    lineOfBusinessCode: string,
    lineOfBusinessName: string,
    articleFilterOption: string,

    maintenanceCategoryClass: string,
    contractScopeClass: string,

    feeId: number,
    employeeId: number,
    employeeFullName: string,

    firstName: string,
    lastName: string,
    birthDateType: 'date' | 'day' | 'none';
    birthDate: Date,
    particularSign: string,

    portable1: string,
    portable2: string,
    email1: string,
    email2: string,

    description: string,

    beneficiaryIsCustomer: boolean,

    amountPaid: number,
    unpaidAmount: number,

    deliveredQuantity: number,

    billingServiceTasks: IBillingServiceTask[],

    billingPackagingDetails: IBillingPackagingDetail[]
  }

  export interface IBillingServiceTask {
    id: number,

    employeeTaskId: number,
    part: number,
    type:	BillingServiceTaskType,

    billingDetailId: number,
    billingDetailPackagingId: number,

    taskCode: string,
    employeeId: number,
    employeeFullName: string,

    amount: number,

    customerFirstName: string,
    customerLastName : string,
    issueDate: Date,
    status: string,
    statusDate: Date
  }

  export interface IBillingPackagingDetail {
    id: number,

    billingDetailId: number,
    part: number,

    isDelivered: boolean,
    deliveryDate: Date,

    type: BillingPackagingDetailType,

    articleId: number,
    articleOption: string,
    serviceId: number,
    personId: number,

    productName: string,

    articleFilterOption: string,

    firstName: string,
    lastName: string,

    portable1: string,
    portable2: string,
    email1: string,
    email2: string,

    beneficiaryIsCustomer: boolean,

    billingServiceTasks: IBillingServiceTask[]
  }

  export interface ICommissionDistribution {
    id: number,
    billingId: number,
    employeeId: number,
    amount: number,

    type:	'employee' | 'manager',

    firstName: string,
    lastName: string
  }

  export interface IBillingDashboardDate {
    
    amount: number,
    amountPaid: number,
    unpaidAmount: number,
    tellerOperationAmount: number,

    countServices: number,
    
    classChatLabels: string[],
    amountProducts: number[],
    
    amountPerEmployee: {label: string, value: number}[],
  }


  export interface IBillingShippingAddress {
    id: number,
    billingId: number,

    city: string,
    countryCode: string,

    postalCode:	string,

    state: string
  }

  export const defaultDashboardDate : IBillingDashboardDate = {
    amount: 0,
    amountPaid: 0,
    unpaidAmount: 0,
    tellerOperationAmount: 0,

    countServices: 0,
    
    classChatLabels: [],
    amountProducts: [],

    amountPerEmployee: [],
  }

  export interface IBillingDashboardMonth {
    
    amount: number,
    amountPaid: number,
    unpaidAmount: number,

    cancelledAmount: number,
    unvalidatedAmount: number,
    tellerOperationAmount: number,

    productTypes: IBillingDetail[],
    lineOfBusinesses: IBillingDetail[],
    products: IBillingDetail[],
    productDetails: IBillingDetail[],

    technicianTasks: {employeeFullName: string, amount: number, count: number}[],
    technicianCustomers: {employeeFullName: string, amount: number, count: number}[],
  }

  export interface IBillingDelivery {
    id: number,
    billingId: number,
    storeId: number,

    reference: string,
    description: string,
    deliveryDate: Date,
    deliveryNumber: string,
    issueDate: Date,
    status: string,
    statusDate: Date,

    billing: IBilling,

    firstName: string,
    lastName: string,
    corporationName: string,
    proformaNumber: string,
    billingNumber: string,

    billingDeliveryDetails: IBillingDeliveryDetail[]
  }

  export interface IBillingDeliveryDetail {
    id: number,
    billingDeliveryId: number,

    type: 'article',
    billingArticleId: number,
    quantity: number,

    productName: string,
    articleOption: string,
    articleFilterOption: string,

    initialQuantity: number,
    deliveredQuantity: number,

    
  }

  

  export const defaultBillingDashboardMonth : IBillingDashboardMonth = {
    amount: 0,
    amountPaid: 0,
    unpaidAmount: 0,

    cancelledAmount: 0,
    unvalidatedAmount: 0,
    tellerOperationAmount: 0,

    productTypes: [],
    lineOfBusinesses: [],
    products: [],
    productDetails: [],

    technicianTasks: [],
    technicianCustomers: [],
  }


  export interface IFinanceDashboard {
    
    billingAmount: number,
    billingUnpaidAmount: number,
    tellerOperationAmount: number,    

    expenseAmount: number,
    paymentAmount: number,
    paymentUnpaidAmount: number,

    
    billingAmountMonth: number,
    billingUnpaidAmountMonth: number,
    tellerOperationAmountMonth: number,    

    expenseAmountMonth: number,
    paymentAmountMonth: number,
    paymentUnpaidAmountMonth: number,

    billingsByProductType: {type: string, amount: number, amountMonth: number}[],
    billingsByLineOfBusiness: {type: string, amount: number, amountMonth: number}[],

    expensesByType: {type: string, amount: number, amountMonth: number}[],

    paymentsByType: {type: string, amount: number, amountMonth: number}[],    
  }

  export const defaultFinanceDashboard : IFinanceDashboard = {
    billingAmount: 0,
    tellerOperationAmount: 0,
    billingUnpaidAmount: 0,

    expenseAmount: 0,
    paymentAmount: 0,
    paymentUnpaidAmount: 0,

    
    billingAmountMonth: 0,
    tellerOperationAmountMonth: 0,
    billingUnpaidAmountMonth: 0,

    expenseAmountMonth: 0,
    paymentAmountMonth: 0,
    paymentUnpaidAmountMonth: 0,

    billingsByProductType: [],
    billingsByLineOfBusiness: [],

    expensesByType: [],
    paymentsByType: []
  }

  export interface IEmployeeBillingDashboard {
    
    taskAmountDay: number,
    taskUnvalidatedAmountDay: number,
    taskCancelledAmountDay: number,    

    customerAmountDay: number,
    customerCancelledAmountDay: number,
    commissionAmountDay: number,

    taskAmountMonth: number,
    taskUnvalidatedAmountMonth: number,
    taskCancelledAmountMonth: number,    

    customerAmountMonth: number,
    customerCancelledAmountMonth: number,
    commissionAmountMonth: number,
    
    billingTasksByEmployee: IBillingServiceTask[],
    billingsByCustomer: IBilling[]
  }

  export const defaultEmployeeBillingDashboard : IEmployeeBillingDashboard = {
    
    taskAmountDay: 0,
    taskUnvalidatedAmountDay: 0,
    taskCancelledAmountDay: 0,    

    customerAmountDay: 0,
    customerCancelledAmountDay: 0,
    commissionAmountDay: 0,

    taskAmountMonth: 0,
    taskUnvalidatedAmountMonth: 0,
    taskCancelledAmountMonth: 0,    

    customerAmountMonth: 0,
    customerCancelledAmountMonth: 0,
    commissionAmountMonth: 0,
    
    billingsByCustomer: [],
    billingTasksByEmployee: []
  }

  export const defaultBilling : IBilling = {
    id: 0,
    onlineCustomerId: 0,
    businessEmployeeId: 0,
    issueDate: new Date(),    
    reference: '',

    showroomId: 0,

    // isObligatory: false,
    // isCancelled: false,
    // cancellationPurpose: '',
    // cancellationDate: new Date(),   
    
       
    // billingTypeName: '',
    // billingCategoryName: '',

    netAmount: 0,
    tax: 0,
    amount: 0,    
    amountPaid: 0, 
    unpaidAmount: 0, 
    //dueAmount: 0,

    commissionAmount: 0,
    
    customerPersonType: 'physical',
    customerFirstName: '',
    customerLastName: '',
    customerBirthDateType: 'date',
    customerBirthDate: new Date(),
    customerParticularSign: '', 

    customerCorporationName: '',
    customerCorporationType: '',
    customerCreationDateType: 'date',
    customerCreationDate: new Date(),

    customerPortable1: '',
    customerPortable2: '',
    customerEmail1: '',
    customerEmail2: '', 


    status: '95',
    statusDate: new Date(),
    statusPurpose: '00',

    businessEmployeeFullName: '',
    businessEmployeeManagerFullName: '',

    proformaNumber: '',
    billingNumber: '',

    billingDetails: [],

    serviceDuplicateCount: 0,

    shippingAddressLine1: '',
    shippingAddressLine2: '',
    shippingAddressCity: '',
    shippingAddressState: '',
    shippingAddressPostalCode: '',
    shippingAddressCountryCode: '',
    shippingAddressName: '',

    billingShippingAddresses: []
  }

  export const defaultBillingDetail : IBillingDetail = {
    id: 0,
    billingId: 0,

    baseAmount: 0,
    discount: 0,
    loaded: 0,
    pricePurpose: '',
    netAmount: 0,
    tax: 0,
    amount: 0,

    isDelivered: true,
    deliveryDate: new Date(),
    isDeliveryDateDefine: true,
  
    type: 'service',

    articleId: 0,
    quantity: 0,
    articleOption: '',
    unityAmount:0,
    serviceId: 0,
    personId: 0,
    packagingId: 0,
    formationId: 0,
    studentId: 0,

    maxDiscountAmount: 0,
    maxLoadedAmount: 0,
    isPriceDpendsOnQuantity: false,
   
    productName: '',
    lineOfBusinessCode: '',
    lineOfBusinessName: '',
    articleFilterOption: '',
    maintenanceCategoryClass: '',
    contractScopeClass: '',

    feeId: 0,
    employeeId: 0,
    employeeFullName: '',

    maintenanceId: 0,
    maintenanceCategory: '',
    maintenanceReference: '',
    maintenanceDescription: '',
    maintenanceStartDate: new Date(),
    maintenanceEndDate: new Date(),
    contractId: 0,
    contractScope: '',
    contractType: '',
    contractDescription: '',
    contractEffectiveDate: new Date(),
    contractExpirationDate: new Date(),

    firstName: '',
    lastName: '',
    birthDateType: 'date',
    birthDate: new Date(),
    particularSign: '',

    portable1: '',
    portable2: '',
    email1: '',
    email2: '',

    description: '',

    beneficiaryIsCustomer: false,

    amountPaid: 0,
    unpaidAmount: 0,

    deliveredQuantity: 0,

    billingServiceTasks: [],

    billingPackagingDetails: []
  }

  export const defaultBillingDelivery : IBillingDelivery = {
    id: 0,
    billingId: 0,
    storeId: 0,

    reference: '',
    description: '',
    deliveryDate: new Date(),
    deliveryNumber: '',
    issueDate: new Date(),
    status: '95',
    statusDate: new Date(),

    billing: defaultBilling,

    firstName: '',
    lastName: '',
    corporationName: '',
    proformaNumber: '',
    billingNumber: '',

    billingDeliveryDetails: []
  }


  export interface IBillingSearch {
    startDate: Date,
    endDate: Date,

    customerName: string,
    customerPortable: string,
    reference: string,
  }

 export interface IBillingDeliverySearch {
    startDate: Date,
    endDate: Date,  
 
    customerName: string,
    deliveryNumber: string,
    proforma: string,
    billingNumber: string,
  }
  

