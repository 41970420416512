
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IContent, IContentSearch } from "../models/Content";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';
//import { IPolicyRisk, IPolicyRiskCoverage } from 'features/contention/models/Policy';


const _ = () => {

    const axios = useAxios(); 

    const createContent = async (content: IContent)  =>       
        await (await axios.post('/api/production/content/create', content)).data;       
        
    const updateContent = async (content: IContent)  =>       
        await (await axios.post('/api/production/content/update', content)).data; 
    
    const getContent = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/content/get-content/${id}`));
      return await data;
    }
   
    const getContentsByContentType = async (contentTypeId: number) : Promise<IContent[]> => {
      
      const {data} = (await axios.get(`/api/production/content/get-contents-by-contentType?&contentTypeId=${contentTypeId}`));
      return await data;
    }

    const getContentsByContentTypeEx = async (contentTypeId: number) : Promise<IContent[]> => {
      
      const {data} = (await axios.get(`/api/production/content/get-contents-by-contentType-ex?&contentTypeId=${contentTypeId}`));
      return await data;
    }

    const getContents = async (contentTypeId: number,criteria: IContentSearch, pagination?: IPagination) : Promise<IContent[]> => {

      const {contentTypeName, contentTypeDescription } = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/production/content/get-contents?contentTypeId=${contentTypeId}&contentTypeName=${contentTypeName}&contentTypeDescription=${contentTypeDescription}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    
        
    return {    
      createContent,
      updateContent,
      getContent,
      getContents,

      getContentsByContentType,
      getContentsByContentTypeEx
    } 
}

export default _;

export interface IFilterContentOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IContent[], React.Dispatch<React.SetStateAction<IContent[]>>],
}

const defaultFilterContentOption: IFilterContentOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterContent = ( contentTypeId: number, onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IContent) => void,
                                            filterOption?: IFilterContentOption  ) => {

  const { getContents } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterContentOption;



  const [headContentCells, setHeadContentCells]  = useState<HeadCell<IContent>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'contentTypeName', label : t('Name'),  display: true, type: 'string', },
    {id:'contentTypeDescription', label : t('Description'),  display: true, type: 'string', },
    //{id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', },
    //{id:'apiAlias', label : t('API alias'),  display: true, type: 'string', },

    // {id: 'quantityAvailable', label : `${t('Quantity')} : ${t('Available')}`,  display: true && (type === 2), type: 'numeric',},
    // {id: 'quantityStock', label : `${t('Quantity')} : ${t('Stock')}`,  display: true && (type === 2), type: 'numeric',}
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
      {name: 'contentTypeName', text: t('Name'), value: ''},
      {name: 'contentTypeDescription', text: t('Description'), value: ''},
      
    ]);    

  const [filteredContents, ] = useState<IContent[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IContent[]> => {
   
    const contentTypeName = filterElements.find( elt => elt.name === 'contentTypeName')?.value || '';
    const contentTypeDescription = filterElements.find( elt => elt.name === 'contentTypeDescription')?.value || '';
    
    const arr = await getContents(contentTypeId,{contentTypeName,contentTypeDescription}, pagination );
    
    return arr;
  }

  const objKey: keyof IContent = 'id';

  return {
    title: t('Content'), headCells: headContentCells, objKey,
    filterElements, rows: filteredContents, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}

