
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IMedia, IMediaSearch } from "../models/Media";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';
//import { IPolicyRisk, IPolicyRiskCoverage } from 'features/mediaion/models/Policy';


const _ = () => {

    const axios = useAxios(); 

    const createMedia = async (media: IMedia)  =>       
        await (await axios.post('/api/production/media/create', media)).data;       
        
    const updateMedia = async (media: IMedia)  =>       
        await (await axios.post('/api/production/media/update', media)).data; 
    
    const getMedia = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/media/get-media/${id}`));
      return await data;
    }
   

    const getMedias = async (criteria: IMediaSearch, pagination?: IPagination) : Promise<IMedia[]> => {

      const {name, description, fileName, url } = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/production/media/get-medias?&name=${name}&description=${description}&fileName=${fileName}&url=${url}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }
     
    return {    
      createMedia,
      updateMedia,
      getMedia,
      getMedias,

    } 
}

export default _;

export interface IFilterMediaOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IMedia[], React.Dispatch<React.SetStateAction<IMedia[]>>],
}

const defaultFilterMediaOption: IFilterMediaOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterMedia = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IMedia) => void,
                                            filterOption?: IFilterMediaOption  ) => {

  const { getMedias } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterMediaOption;



  const [headMediaCells, setHeadMediaCells]  = useState<HeadCell<IMedia>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    
    {id:'url', label : t('Url'),  display: true, type: 'string', },
    {id:'fileName', label : t('FileName'),  display: true, type: 'string', },
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      {name: 'fileName', text: t('FileName'), value: ''},
      {name: 'url', text: t('Url'), value: ''},
    ]);    

  const [filteredMedias, ] = useState<IMedia[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IMedia[]> => {
   
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    const fileName = filterElements.find( elt => elt.name === 'fileName')?.value || '';
    const url = filterElements.find( elt => elt.name === 'url')?.value || '';
    
    const arr = await getMedias({ name,description,fileName,url}, pagination );
    
    return arr;
  }

  const objKey: keyof IMedia = 'id';

  return {
    title: t('Media'), headCells: headMediaCells, objKey,
    filterElements, rows: filteredMedias, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}

