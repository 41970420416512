import { IExtension, IExtensionValue } from "features/configuration/models/ExtensionType";

export interface IContent {
  id: number,

  contentTypeId: number,
  isActive: boolean,
  displayOrder: number,

  contentTypeName: string,
  contentTypeDescription: string,

  productId: number,
  productName: string,

  parentContentId: number,
  parentContentDef: string,
      
  contentExtensions: IExtensionValue[]
}


export interface IContentSearch {
  contentTypeName: string,
  contentTypeDescription: string
}

export const defaultContent : IContent = {
  id: 0,

  contentTypeId: 0,
  isActive: true,
  displayOrder: 0,

  contentTypeName: '',
  contentTypeDescription: '',

  productId: 0,
  productName: '',

  parentContentId: 0,
  parentContentDef: '',
    
  contentExtensions: []
}
