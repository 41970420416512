

import React from 'react';
import { Navigate, Outlet, Route, Routes} from 'react-router-dom';


import {  useRecoilValue, } from 'recoil';

///
import { currentUserSessionAtom } from 'library/store';

import { Login } from './Login';
import { Home } from 'features/Home';

import { ApplicationSetupForm, defaultApplicationSetup,
    ApplicationQueryForm, defaultApplicationQuery, ContentTypeForm, defaultContentType, 
    ProductForm,
    defaultProduct} from 'features/setup';

import { RoleForm, defaultRole,
         UserForm, defaultUser, 
         OnlineCustomerForm,
         defaultOnlineCustomer} from 'features/security';

import { ExternalLibraryForm, defaultExternalLibrary,
         EnumerationForm, defaultEnumeration,
         ReportForm, defaultReport,
         ExtensionTypeForm, defaultExtensionType, 
         StoreForm,
         defaultStore,
         DeliveryForm,
         defaultDelivery} from 'features/configuration';


import { 
    ContentForm,
         MediaForm, defaultContent, defaultMedia,
         
           } from 'features/production';

import { JobForm, defaultJob,
         SmsApiForm, defaultSmsApi } from 'features/misc';


import { PasswordChangeForm, defaultPasswordChange } from 'features/identity';
import { BillingForm, defaultBilling } from 'features/finance';



            

const ProtectedRoute = ( props: {redirectPath?: string} ) : React.ReactElement => {
    const {redirectPath} = props;

    const {isAuthenticated } = useRecoilValue(currentUserSessionAtom);
    
    return !isAuthenticated ? <Navigate to={redirectPath || '/login'} replace /> : <Outlet />;
}


export const AppRoute = () => {
    
    return (
    <Routes>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        
        <Route element={<ProtectedRoute />}>
          <Route path="home" element={<Home />} />

          {/* ------ Setup ----- */}
          <Route path="applicationSetup/:id" element={<ApplicationSetupForm {...defaultApplicationSetup} />} />          
          <Route path="applicationQuery/:id" element={<ApplicationQueryForm {...defaultApplicationQuery} />} />
          
          <Route path="contentType/:id" element={<ContentTypeForm {...defaultContentType} />} />

          <Route path="product/:id" element={<ProductForm {...defaultProduct} />} />
          
          {/* ------ Security ----- */}
          <Route path="role/:id" element={<RoleForm {...defaultRole} />} />
          <Route path="user/:id" element={<UserForm {...defaultUser} />} />
          <Route path="onlineCustomer/:id" element={<OnlineCustomerForm {...defaultOnlineCustomer} />} />

          {/* ------ Configuration ----- */}
          <Route path="externalLibrary/:id" element={<ExternalLibraryForm {...defaultExternalLibrary} />} />
          <Route path="extensionType/:id" element={<ExtensionTypeForm {...defaultExtensionType} />} />
          <Route path="enumeration/:id" element={<EnumerationForm {...defaultEnumeration} />} />
          <Route path="report/:id" element={<ReportForm {...defaultReport} />} />
        
          {/* ------ Production ----- */}
          <Route path="media/:id" element={<MediaForm {...defaultMedia} />} />
          <Route path="content/:id" element={<ContentForm {...defaultContent} />} />
          
          {/* ------ Operation ----- */}
          <Route path="store/:id" element={<StoreForm {...defaultStore} />} />
          <Route path="delivery/:id" element={<DeliveryForm {...defaultDelivery} />} />

          <Route path="billing/:id" element={<BillingForm {...defaultBilling} />} />
                   

          {/* ------ Misc ----- */}
          <Route path="job/:id" element={<JobForm {...defaultJob} />} />
          <Route path="smsApi/:id" element={<SmsApiForm {...defaultSmsApi} />} />

          {/* ------ User profile ----- */}
          <Route path="passwordChange" element={<PasswordChangeForm {...defaultPasswordChange} />} />
          
        </Route>
        
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
    </Routes>
    );
    
}


