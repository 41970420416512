export interface IOnlineCustomer {
    id: number,

    userName: string,
    firstName: string,
    lastName: string,
    dateOfBirth: Date,
    portable: string,
    email: string,

    isActive: boolean,

    town: string,
    reserved1: string,
    readonlyeserved2: string,
    addressLine: string,

    passwordHash: string,
    salt: string,

    password: string,
    passwordMD5: string,

    userValidationCode: string,
    token: string
  }
  

  export interface IOnlineCustomerValidationCode {
    id: number,

    userName: string,
    firstName: string,
    lastName: string,
    dateOfBirth: Date,
    portable: string,
    email: string,

    userValidationCode: string,
    token: string

  }

  export const defaultOnlineCustomer: IOnlineCustomer = {
    id: 0,

    userName: '',
    firstName: '',
    lastName: '',
    dateOfBirth: new Date(),
    portable: '',
    email: '',

    isActive: true,
    
    town: '',
    reserved1: '',
    readonlyeserved2: '',
    addressLine: '',

    passwordHash: '',
    salt: '',

    password: '',
    passwordMD5: '',

    userValidationCode: '',
    token: ''
   }

   export interface IOnlineCustomerSearch {
    userName: string,
    firstName: string,
    lastName: string,
    email: string,
    portable: string
   }