
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, ElementType}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

//import colorNameList from 'color-name-list';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import QueueIcon from '@mui/icons-material/Queue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import BackpackIcon from '@mui/icons-material/Backpack';

import NotInterestedIcon from '@mui/icons-material/NotInterested';
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';
import EventOutlined from '@mui/icons-material/EventOutlined';
import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined';
import ListOutlined from '@mui/icons-material/ListOutlined';
import Filter1Outlined from '@mui/icons-material/Filter1Outlined';
import DialpadOutlined from '@mui/icons-material/DialpadOutlined';
import VideoLibraryOutlined from '@mui/icons-material/VideoLibraryOutlined';
import ToggleOffOutlined from '@mui/icons-material/ToggleOffOutlined';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';

import { ChromePicker, ColorResult } from 'react-color';



import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom, currentUserSessionAtom } from 'library/store';
import useOnlineCustomerService, { useBasicFilterOnlineCustomer } from './services/OnlineCustomer';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, Popover, useTheme, SvgIconProps } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IOnlineCustomer, defaultOnlineCustomer } from './models/OnlineCustomer';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

//import useDeliveryService from 'features/configuration/services/Delivery';

import useUtils from 'library/utils';

import IEnumeration, { Enum_LINE_OF_BUSINESS, Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, 
      Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ,IEnumerationItem, Enum_ARTICLE_WRAPPING } from 'features/configuration/models/Enumeration';

//import {ISharing} from 'features/setup/models/Sharing';
//import useSharingService, { useBasicFilterSharing } from 'features/setup/services/Sharing';

import { IExtension, IExtensionType, _ExtensionType, defaultExtension  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling, carouselImage } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { debounce, sum } from 'lodash';
import { GrClose, GrSearch } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';
import { ExtensionFormDialog } from 'components/ExtensionFormDialog';
//import { IArticleStock } from 'features/configuration/models/Delivery';


type ExtensionUsage = 'content-A' | 'content-U';


const iconFromBaseType = (dataType: _ExtensionType) : ElementType<SvgIconProps> => {
  switch(dataType) {
    case 'boolean': return ToggleOffOutlined;
    case 'date': return EventOutlined;
    case 'decimal': return Filter1Outlined;
    case 'entity': return VideoLibraryOutlined;
    case 'enumeration': return ListOutlined;
    case 'float': return Filter1Outlined;
    case 'integer': return DialpadOutlined;
    case 'text': return DescriptionOutlined;
    case 'time': return AccessTimeOutlined;
    
    default: return DescriptionOutlined;
  }
  
}

export const OnlineCustomerForm: FC<IOnlineCustomer> = (props: IOnlineCustomer = defaultOnlineCustomer) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { applicationSetup} = useRecoilValue(currentUserSessionAtom);

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  
  const {capitalizeFirstLetter} = useUtils();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  //const colors = colorNameList.filter(c => c.name.split(' ').length === 1 && c.name.length <= 5).map((color) => color);
  
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterOnlineCustomer = useBasicFilterOnlineCustomer( 
    (event: React.MouseEvent<unknown>, row: IOnlineCustomer) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}

  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );


  

  const methods = useForm<IOnlineCustomer>({defaultValues: {...defaultOnlineCustomer, }});

  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  

  //const watchBase64Picture = watch('base64Picture');
  
  const watchUserName = watch('userName');    
   

  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [color, setColor] = useState<string>('#000');

 

  const queryClient = useQueryClient();
  
    const {data: _data, refetch} = useQuery<IOnlineCustomer>(['OnlineCustomer', _id], () => retrieveEntity('OnlineCustomer',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    
      useEffect( () => {        
        setCurrentFormNameAtom(t('Customer'));
        setCurrentBasicTextFilterProps(basicFilterOnlineCustomer);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            setCurrentFormNameAtom(_id>0?`${t('Customer')} - # ${_id} # -`: t('Customer') );
            if(_id > 0)
              retrieveData('OnlineCustomer',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);


        const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
                      
        }
              
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('OnlineCustomer', _id)) {
          setIsSaveLoading(false);
             return;
        }          
  
        const data = getValues(); 
        console.log({data});       
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('OnlineCustomer', _id);
      }

    
      
      const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
        queryClient.invalidateQueries(['OnlineCustomer',_id]);        
        await retrieveData('OnlineCustomer',_id, refetch);        
        reset(_data);        
      }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                                
                                <TextField sx={{width:'calc(50% - 8px)'}} id="name" label={t('User name')} {...register('userName')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  />   
                                <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                  label={`${t('Active ?')}`}
                                  control={
                                  <Controller
                                    name={`isActive`}
                                    control={control}
                                    render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                  />} />
                                   
                                                                                  
                            </Box>   
                                                        
                                                    
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="lastName" label={t('Last name')} {...register('lastName')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(50% - 8px)'}} id="firstName" label={t('First name')} {...register('firstName')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  /> 
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(50% - 8px)'}} id="email" label={t('Email')} {...register('email')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  /> 
                            </Box>
                            
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>      
                        <Box sx={{ mt: 1, width: '100%' }} >
                                                     
                            
                        </Box>    
                        <Box sx={{ mt: 1, width: '100%' }} >
                          
                        </Box>                 
                        
                      </Stack>
                    </Grid>                       
                </Grid>
            </Box>
        </FormProvider> 
  )
}

