import { IAppTheme, defaultAppTheme } from "themes/commonStyles"


export interface IApplicationSetup {
    id: number,
    companyName: string,
    description: string,
    defaultLanguage: string,

    isPasswordComplexityAllowed: boolean,
    passwordDuration: number,
    passwordRotation: number,
    maxAccessFailedCount: number,
    accessLockoutDuration: number,
    
    applicationApiToken: string,

    whatsAppBaseUrl: string,
    whatsAppIdentityToken: string,
    whatsAppLogin: string,
    whatsAppPasswordMd5: string,

    whatsAppPassword: string,
    isPasswordChanged: boolean,

    webhookToken: string,
    webhookUrl: string,

    isCommissionDisburseOn: boolean,
    minimumDisbursementCommissionAmount: number,

    valueOfPoint: number,
    minimumDisbursementPointCount: number,

    minAppointmentStartTime: Date,
    maxAppointmentStartTime: Date,

    certificateSmsExpression: string,

    certificateDocumentExpression: string,
    certificateImageExpression: string,
    certificateQrCodeExpression: string,    
    
    isSmsOn: boolean,
    isWhatsAppOn: boolean,
    isReportScheduleOn: boolean,
    isFeatureScheduleOn: boolean,
    notificationWhatsAppNumber1: string,
    notificationWhatsAppNumber2: string,

    // issuePeriod: number,
    // isAutomaticIssueDate: boolean,
    // issueDateMaxDelayMonthClosing: number,
    // issueDateMaxDelayYearClosing: number,
    // isAutomaticClosedAfterDelay: boolean,
    // proformaNumberExpression: string,
    // billingNumberExpression: string,
    // deliveryNumberExpression: string,
    // resetProformaSequenceByYear: boolean,
    // resetBillingSequenceByYear: boolean,
    // resetDeliverySequenceByYear: boolean,

    activeContentColor: string,
    inactiveContentColor: string,
    // nonTicketableMessageColor: string,
    // ticketableMessageColor: string,
    // joinedMessageColor: string,


    smtpServer: string,
    smtpPort: number,
    smtpUsername: string,
    smtpPassword: string,
    smtpSenderEmail: string,
    smtpUseTls: boolean,
    smtpServerType: string,
    validationCodeReportId: number,
    validationCodeDefaultTransmissionMode: string,
    passwordResetReportId: number,
    passwordResetDefaultTransmissionMode: string,
    billingReportId: number,
    billingDefaultTransmissionMode: string,

    validationCodeReportName: string,
    passwordResetReportName: string,
    billingReportName: string,

    defaultOnlineStoreId: number,

    payPalClientID: string,
    payPalSecretKey: string,
    isPayPalLive: boolean,

    hasService: boolean,
    hasFee: boolean,
    hasArticle: boolean,
    hasPackaging: boolean,
    hasFormation: boolean,
    hasMaintenance: boolean,
    hasContract: boolean,

    applicationTheme: IAppTheme,
  }


  export const defaultApplicationSetup : IApplicationSetup = {
    id: 0,
    companyName: '',
    description: '',
    defaultLanguage: 'fr-FR',
    
    isPasswordComplexityAllowed: false,
    passwordDuration: 0,
    passwordRotation: 0,
    maxAccessFailedCount: 0,
    accessLockoutDuration: 0,

    applicationApiToken: '',
    
    whatsAppBaseUrl: '',
    whatsAppIdentityToken: '',
    whatsAppLogin: '',
    whatsAppPasswordMd5: '',

    whatsAppPassword: '',
    isPasswordChanged: false,

    webhookToken: '',
    webhookUrl: '',

    isCommissionDisburseOn: true,
    minimumDisbursementCommissionAmount: 10000,

    valueOfPoint: 0,
    minimumDisbursementPointCount: 0,

    minAppointmentStartTime: new Date(),
    maxAppointmentStartTime: new Date(),

    certificateSmsExpression: '',

    certificateDocumentExpression: '',
    certificateImageExpression: '',
    certificateQrCodeExpression: '',

    isSmsOn: true,
    isWhatsAppOn: true,
    isReportScheduleOn: true,
    isFeatureScheduleOn: true,
    notificationWhatsAppNumber1: '',
    notificationWhatsAppNumber2: '',

    // issuePeriod: (new Date()).getFullYear()*100+1,
    // isAutomaticIssueDate: true,
    // issueDateMaxDelayMonthClosing: 0,
    // issueDateMaxDelayYearClosing: 0,
    // isAutomaticClosedAfterDelay: false,
    // proformaNumberExpression: '',
    // billingNumberExpression: '',
    // deliveryNumberExpression: '',
    // resetProformaSequenceByYear: true,
    // resetBillingSequenceByYear: true,
    // resetDeliverySequenceByYear: true,
    
    activeContentColor: '#4CAF50',
    inactiveContentColor: '#4CAF50',
    // ticketableMessageColor: '#4CAF50',
    // joinedMessageColor: '#4CAF50',

    smtpServer: '',
    smtpPort: 0,
    smtpUsername: '',
    smtpPassword: '',
    smtpSenderEmail: '',
    smtpUseTls: false,
    smtpServerType: '',
    validationCodeReportId: 0,
    validationCodeDefaultTransmissionMode: '',
    passwordResetReportId: 0,
    passwordResetDefaultTransmissionMode: '',
    billingReportId: 0,
    billingDefaultTransmissionMode: '',

    validationCodeReportName: '',
    passwordResetReportName: '',
    billingReportName: '',

    defaultOnlineStoreId: 0,

    payPalClientID: '',
    payPalSecretKey: '',
    isPayPalLive: false,

    hasService: false,
    hasFee: false,
    hasArticle: false,
    hasPackaging: false,
    hasFormation: false,
    hasMaintenance: false,
    hasContract: false,

    applicationTheme: defaultAppTheme
  }

  

  export interface IApplicationSetupSearch {
    companyName: string
  }